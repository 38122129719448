import React, { useCallback, useState, useEffect, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Components } from "@ais3p/ui-framework";
import MenuItems from "./MenuItems";

import "./css/index.scss";

/**
 * Компонент для отображения выпадающего списка из кнопки
 * 
 * @param {String} icon название иконки кнопки  из ui-framework
 * @param {String} text текст кнопки 
 * @param {String} tooltip текст всплывающей подсказки для кнопки
 * @param {String} tooltipPlacement позиция всплывающей подсказки для кнопки: top, right, bottom, left
 * @param {String} className пользовательский className
 * @param {String} color цвет кнопки из ui-framework: positive, negative, action, dark, light
 * @param {Array<Object>} items конфигурация пунктов и подпунктов выпадющего списка. 
 * Конфигурация Item должна содержать в себе: 
 *   title {String} текст пункта 
 *   icon {String} название иконки из ui-framework, если у пункта необходима иконка  
 *   items {Array<Object>} конфигурация вложенных пунктов
 * @param {Function} onClickItem  callback ф-я на нажатие одного из пунктов в меню. 
 * В парметре приходит item - конфигурация пункта
 * 
 * @returns {Element}
 */
const DropdownButton = ({ 
  icon,
  text,
  tooltip, 
  tooltipPlacement = "right",
  className, 
  color, 
  items,
  onClickItem 
}) => {
  const [isDropdown, setIsDropdown] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (isDropdown && ref.current && !ref.current.contains(event.target)) {
        setIsDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [isDropdown]);

  const onClickButton = useCallback(() => {
    setIsDropdown((state) => {
      return !state;
    });
  }, [isDropdown]);

  const onClickItemFn = useCallback((item) => {
    setIsDropdown(false);
    onClickItem && onClickItem(item);
  }, [onClickItem]);

  const iconButton = useMemo(() => {
    if (!!icon) {
      return icon;
    }
    return isDropdown ? "chevron-up-M" : "chevron-down-M";
  }, [icon, isDropdown]);

  return (
    <div
      ref={ref}
      className={classNames("dropdown-button", className, {
        "revert-button": !icon
      })} 
    >
      <Components.Tooltip content={tooltip} placement={tooltipPlacement}>
        <div 
          className={classNames("dropdown-button-content", 
          //   {
          //   "btn-group": !!icon
          // }
          )}
        >
          <Components.Button
            text={text}
            icon={iconButton}
            className={className}
            color={color}
            onPress={onClickButton}
            isSelected={isDropdown}
          />
          {/* {!!icon &&
            <Components.Button
              color={color}
              icon={isDropdown ? "chevron-up-M" : "chevron-down-M"}
              onPress={onClickButton}
              isSelected={isDropdown}
            />
          } */}
        </div>
      </Components.Tooltip>  
      {isDropdown && items && items.length > 0 &&
        <MenuItems 
          depthLevel={0}
          items={items}
          isDropdown={isDropdown}
          onClickItem={onClickItemFn}  
        />
      }
    </div>
  );
};

DropdownButton.propTypes = {
  icon:             PropTypes.string,
  text:             PropTypes.string,
  tooltip:          PropTypes.string,
  tooltipPlacement: PropTypes.string,
  className:        PropTypes.string,
  color:            PropTypes.string,
  items:            PropTypes.array,
  onClickItem:      PropTypes.func 
};

export default DropdownButton;
