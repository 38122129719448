import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { Components } from "@ais3p/ui-framework";
import MenuItems from "./MenuItems";

/**
 * Пункт в выпадающем списке из кнопки DropdownButton
 * 
 * @param {Number} depthLevel уровень вложености меню
 * @param {Boolean} isDropdown признак отображения меню
 * @param {Object} item конфигурация пункта в выпадющем списке. 
 * @param {String} item.title текст пункта 
 * @param {String} item.icon название иконки из ui-framework, если у пункта необходима иконка  
 * @param {Array<Object>} item.items конфигурация вложенных пунктов
 * @param {Function} onClickItem  callback ф-я на нажатие одного из пунктов в меню. 
 * В парметре приходит item - конфигурация пункта
 * @param {Number} index индекс пункта в меню
 * 
 * @returns {Element}
 */
const MenuItem = ({ item, depthLevel, isDropdown, onClickItem, index }) => {
  const [dropdown, setDropdown] = useState(isDropdown);
  const ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [dropdown]);

  const onMouseEnter = useCallback(() => {
    setDropdown(true);
  }, []);

  const onMouseLeave =  useCallback(() => {
    setDropdown(false);
  }, []);

  const onClickItemFn = useCallback(() => {
    dropdown && setDropdown(false);
    onClickItem  && onClickItem(item);
  }, [dropdown, onClickItem, item]);
  
  return (
    <li
      className="menu-item"
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClickItemFn}
    > 
      {item.icon && <Components.Icon name={item.icon} />}
      <div className="menu-item-title">
        {item.title}
      </div>
      {item.items && item.items.length > 0 &&
      <>
        <Components.Icon
          name="chevron-right-M"
          className="chevron-right"
        />
        <MenuItems 
          items={item.items}
          isDropdown={dropdown}
          depthLevel={depthLevel + 1}
          menuIndex={index}
          onClickItem={onClickItem}
        />
      </>
      }
    </li>
  );
};

MenuItem.propTypes = {
  item:        PropTypes.object.isRequired, 
  depthLevel:  PropTypes.number, 
  isDropdown:  PropTypes.bool, 
  index:       PropTypes.number,
  onClickItem: PropTypes.func
};

export default MenuItem;
