import { action, observable, computed } from "mobx";

import Api from "../api/journalApi";
import JournalModel from "../models/journalModel";

/**
 * Хранилище журнала записей изменений
 *
 * @class JournalStore
 */
class JournalStore {
  @observable
  records = []; // список записей журнала
  @observable
  totalRecords = 0; // кол-во доступных записей
  @observable
  perPage = 20; // кол-во записей на странице
  @observable
  currentPage = 1; // текущая страница
  @observable
  filters = {}; // заданыне фильтры
  @observable
  isProcessing = false;

  constructor(rootStore) {
    this.rootStore = rootStore;

    this.api = new Api(rootStore);
  }

  @action
  setIsProcessing(value) {
    this.isProcessing = value;
  }


  /**
   * Получить запись журнала
   *
   * @param  {String} uid записи
   *
   * @return  {JournalModel}
   */
  getRecord(uid) {
    return this.records.get(uid);
  }

  /**
   * Получить Пользовтеля АИС ППП
   *
   * @param  {String} uid пользователя
   *
   * @return  {UserModel}
   */
  getUser(uid) {
    return this.rootStore.userStore.get(uid);
  }

  /**
   * Загрузить записи журнала
   *
   * @return {Promise}
   */
  async loadRecords(params = {}) {
    this.clearRecords();
    this.setIsProcessing(true);
    try {
      const data = await this.api.loadHistory(params);
      if (data) {
        (data.entries || []).forEach((item) => {
          this.addRecord(JournalModel.create(item, this));
        });

        this.setTotalRecords(data.total);
      }

      if (params.perPage) {
        this.setPerPage(params.perPage);
      }

      if (params.page) {
        this.setCurrentPage(params.page);
      }

      return data;
    } catch (e) {
      this.onError(e);
    } finally {
      this.setIsProcessing(false);
    }
  }

  /**
   * @action
   * Добавить запись журнала
   *
   * @param {JournalModel} record
   *
   * @return {JournalModel}
   */
  @action
  addRecord(record) {
    this.records.push(record);

    return record;
  }

  /**
   * @action
   * Очистить список прав доступа
   *
   */
  @action
  clearRecords() {
    this.records = [];
  }

  /**
   * @action
   * Задать номер текущей страницы
   *
   */
  @action
  setCurrentPage(value) {
    this.currentPage = value;
  }

  /**
   * @action
   * Задать кол-во записей на стринице
   *
   */
  @action
  setPerPage(value) {
    this.perPage = value;
  }

  /**
   * @action
   * Задать общее кол-во записей
   *
   */
  @action
  setTotalRecords(value) {
    this.totalRecords = value;
  }

  /**
   * @action
   * Задать фильтры для заргруки списка записей журнала
   *
   */
  @action
  setFilters(filters = {}) {
    this.filters = filters;

    const params = {
      page:    1,
      perPage: this.perPage,
      ...this.filters
    };
    this.loadRecords(params);
  }

  /**
   * @action
   * Отобразить/скрыть панель с фильтрами
   *
   */
  @action
  toggleShowFilters() {
    this.isShownFilters = !this.isShownFilters;
  }


  /**
   * @computed
   * Список записей журнала
   *
   * @return {Array <{JournalModel}>}
   */
  @computed
  get recordList() {
    // return Array.from(this.records.values());
    return this.records;
  }

  /**
   * @computed
   * Выставлен ли фильтр
   *
   * @return {Boolean}
   */
  @computed
  get filtersIsActive() {
    return Object.keys(this.filters).length > 0;
  }

  /**
   * @computed
   * Старт номеров записей на текущей странице
   *
   * @return {Number}
   */
  @computed
  get numStart() {
    return (this.currentPage - 1) * this.perPage;
  }

  /**
   * @computed
   * Кол-во страниц
   *
   * @return {Number}
   */
  @computed
  get pageCount() {
    if (this.totalRecords === 0) {
      return 0;
    }

    return  Math.ceil(this.totalRecords / this.perPage);
  }

  /**
   * @computed
   * Можно ли перейти на предыдущую страницу
   *
   * @return {Boolean}
   */
  @computed
  get canPreviousPage() {
    return this.currentPage > 1;
  }

  /**
   * @computed
   * Можно ли перейти на следуюущую страницу
   *
   * @return {Number}
   */
  @computed
  get canNextPage() {
    return this.currentPage < this.pageCount;
  }

  /**
   * Задать кол-во записей на страницу
   *
   * @param {Number} size кол-во записей на страницу
   */
  setPageSize(size) {
    const params = {
      page:    1,
      perPage: size,
      ...this.filters
    };
    this.loadRecords(params);
  }

  /**
   * Перейти на  страницу
   *
   * @param {Number} page номер страницы, на которую нужно перейти
   */
  goToPage(page) {
    if (page < 1 || page > this.pageCount) {
      return;
    }

    const params = {
      page,
      perPage: this.perPage,
      ...this.filters
    };
    this.loadRecords(params);
  }

  /**
   * Перейти на следующую страницу
   *
   */
  goToNextPage() {
    if (!this.canNextPage) {
      return;
    }

    const params = {
      page:    this.currentPage + 1,
      perPage: this.perPage,
      ...this.filters
    };
    this.loadRecords(params);
  }

  /**
   * Перейти на предыдущую страницу
   *
   */
  goToPreviousPage() {
    if (!this.canPreviousPage) {
      return;
    }

    const params = {
      page:    this.currentPage - 1,
      perPage: this.perPage,
      ...this.filters
    };
    this.loadRecords(params);
  }

  /**
   * @action
   * Очистить данные
   *
   */
  clear() {
    this.cleaRecords();
  }

  onError(msg) {
    // let message = msg;
    // if (msg && msg.message) {
    //   message = msg.message;
    // }
    this.rootStore.onError(msg);
  }

  destroy() {
    this.clear();
    if (this.api) {
      this.api.destroy();
    }
  }
}

export default JournalStore;
