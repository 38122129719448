import { action, computed, observable } from "mobx";
import BaseTextObject from "./BaseTextObject";

class BaseBoxClass extends BaseTextObject {
  @observable
  idsArray = [];

  @action
  init(data, parent) {
    if (parent) {
      this.setParent(parent);
    }
    this.className = data.class;
    this.idsArray.clear();
    data.items &&
      data.items.forEach((itemId) => {
        this.addItemById(itemId);
      });
    data.elements &&
      data.elements.forEach((itemId) => {
        this.addItemById(itemId);
      });
    data.rubrics &&
      data.rubrics.forEach((itemId) => {
        this.addItemById(itemId);
      });
    data.chunks &&
      data.chunks.forEach((itemId) => {
        this.addItemById(itemId);
      });
    data.lines &&
      data.lines.forEach((itemId) => { 
        this.addItemById(itemId);
      });
    data.cells &&
      data.cells.forEach((itemId) => {
        this.addItemById(itemId);
      });
    data.rows &&
      data.rows.forEach((itemId) => {
        this.addItemById(itemId);
      });
    data.text && this.addItemById(data.text);
    data.caption && this.addItemById(data.caption, true);
    data.inline && this.addItemById(data.inline, true);
    data.header && this.addItemById(data.header, true);
  }

  @action
  addItemId(id) {
    this.idsArray.push(id);
  }

  @action
  addItemById(id, doNotAdd = false) {
    const item = this.getItemById(id);
    if (item) {
      item.setParent(this);
      if (!doNotAdd) {
        this.addItemId(id);
      }
    }
  }

  @action
  deleteItemId(id) {
    this.idsArray.remove(id);
  }

  @action
  delete(id, noPersist = false) {
    this.deleteItemId(id);
    this.store.deleteItemById(id, noPersist);
  }

  @action
  getPrevId(id) {
    const index = this.getItemIndexById(id);
    return index > 0 && this.idsArray.length ? this.idsArray[index - 1] : null;
  }

  @action
  getNextId(id) {
    const index = this.getItemIndexById(id);
    return this.idsArray.length && index < this.idsArray.length - 1
      ? this.idsArray[index + 1]
      : null;
  }

  @action
  getItemIndexById(id) {
    const index = this.idsArray.findIndex((itemId) => {
      return itemId === id;
    });
    return index;
  }

  @action
  deleteItem(id) {
    this.idsArray.delete(id);
    this.store.deleteItemById(id);
  }

  @action
  setPrevChild(id, signal, offset) {
    const prevId = this.getPrevId(id);
    if (prevId) {
      const prevItem = this.getItemById(prevId);
      if (prevItem.className === "text.chunk.InlineFormula") {
        this.setPrevChild(prevItem.uid);
        return;
      }
      prevItem && prevItem.setEditing(offset);
    } else {
      this.parent && this.parent.setPrevChild(this.uid, signal, offset);
    }
  }

  @action
  setNextChild(id, signal, offset) {
    const nextId = this.getNextId(id);
    if (nextId) {
      const nextItem = this.getItemById(nextId);
      if (nextItem.className === "text.chunk.InlineFormula") {
        this.setNextChild(nextItem.uid);
        return;
      }
      nextItem && nextItem.setEditing(offset);
    } else {
      this.parent && this.parent.setNextChild(this.uid, signal, offset);
    }
  }

  @action
  async setEditing(offset) {
    if (!this.idsArray.length) {
      return null;
    }
    let itemId = this.idsArray[0];
    if (this.store.delta < 0) {
      itemId = this.idsArray[this.idsArray.length - 1];
    }
    const item = this.getItemById(itemId);
    await (item && item.setEditing(offset));
  }

  @action
  async splitAfter(id) {
    if (!this.parent || !this.store) {
      return null;
    }
    const newItem = this.parent.createAfter(this.uid, {
      uid:        this.store.getUid(),
      class:      this.className,
      forceEmpty: true
    });
    let foundItem = false;
    const deletePromises = [];
    this.items.forEach((item) => {
      const itemId = item.uid;
      if (foundItem) {
        newItem.addItemById(itemId);
        this.deleteItemId(itemId);
        deletePromises.push(item.persistDelete());
      }
      if (id === itemId) {
        foundItem = true;
      }
    });
    await Promise.all(deletePromises);
    await newItem.persistCreate(true);
    return newItem;
  }

  @action
  insertAfter(item, id) {
    const index = this.idsArray.findIndex((itemId) => {
      return itemId === id;
    });
    if (index !== -1) {
      this.idsArray.splice(index + 1, 0, item.uid);
    } else {
      if (item.className === "text.container.Rubric") {
        if (this.firstRubricIndex !== null) {
          this.idsArray.splice(this.firstRubricIndex, 0, item.uid);
        } else {
          this.idsArray.splice(this.idsArray.length, 0, item.uid);
        }
      } else {
        this.idsArray.splice(0, 0, item.uid);
      }
    }

    return item;
  }

  @action
  createAfter(id, data, kind) {
    const item = this.store.createItem(data, this, kind);
    const index = this.idsArray.findIndex((itemId) => {
      return itemId === id;
    });

    if (index !== -1) {
      this.idsArray.splice(index + 1, 0, item.uid);
    } else {
      if (data.class === "text.container.Rubric") {
        if (this.firstRubricIndex !== null) {
          this.idsArray.splice(this.firstRubricIndex, 0, item.uid);
        } else {
          this.idsArray.splice(this.idsArray.length, 0, item.uid);
        }
      } else {
        this.idsArray.splice(0, 0, item.uid);
      }
    }

    return item;
  }

  @action
  createBefore(id, data, kind) {
    const item = this.store.createItem(data, this, kind);
    const index = this.idsArray.findIndex((itemId) => {
      return itemId === id;
    });
  
    if (index !== -1) {
      this.idsArray.splice(index, 0, item.uid);
    } else {
      if (data.class === "text.container.Rubric") {
        if (this.firstRubricIndex !== null) {
          this.idsArray.splice(this.firstRubricIndex, 0, item.uid);
        } else {
          this.idsArray.splice(this.idsArray.length, 0, item.uid);
        }
      } else {
        this.idsArray.splice(0, 0, item.uid);
      }
    }
  
    return item;
  }

  @action
  createAtIndex(index, data, kind) {
    const item = this.store.createItem(data, this, kind);
    if (index !== -1) {
      this.idsArray.splice(index + 1, 0, item.uid);
    } else {
      if (data.class === "text.container.Rubric") {
        if (this.firstRubricIndex !== null) {
          this.idsArray.splice(this.firstRubricIndex, 0, item.uid);
        } else {
          this.idsArray.splice(this.idsArray.length, 0, item.uid);
        }
      } else {
        this.idsArray.splice(0, 0, item.uid);
      }
    }

    return item;
  }

  @action
  unsetEditingChunkId(id) {
    this.parent.unsetEditingChunkId(id);
  }

  @computed
  get plainText() {
    let text = "";
    if (this.number !== null && this.number !== undefined) {
      text += `${this.number} `;
    }
    if (!!this.caption && !!this.caption.title) {
      text += `${this.caption.title} `;
    }
    text += this.items.map((item) => {
      return item.plainText || "";
    }).join(" ");
    
    return text;
  }

  @computed
  get isLocked() {
    return !!this.lockData;
  }

  @computed
  get isChildLocked() {
    let childLocked = false;
    this.items.forEach((child) => {
      if ((child.isLocked && !child.isLockedByMe) || child.isChildLocked) {
        childLocked = true;
      }
    });
    if (this.caption && (this.caption.isLocked && !this.caption.isLockedByMe)) {
      childLocked = true;
    }
    return childLocked;
  }

  @computed
  get isChildLockedByMe() { // means ONLY by ME
    if (this.isChildLocked) { 
      return false;
    }
    let childLocked = false;
    this.items.forEach((child) => {
      if ((child.isLocked && child.isLockedByMe) || child.isChildLockedByMe) {
        childLocked = true;
      }
    });
    if (this.caption && ((this.caption.isLocked && this.caption.isLockedByMe) || this.caption.isChildLockedByMe)) {
      childLocked = true;
    }

    return childLocked;
  }

  @computed
  get firstRubricIndex() {
    let index = null;
    this.items.forEach((item, i) => {
      if (item.isRubric && index === null) {
        index = Math.max(0, i - 1);
      }
    });
    return index;
  }

  @computed
  get items() {
    const data = [];
    this.idsArray.forEach((id) => {
      const item = this.getItemById(id);
      data.push(item);
    });
    return data;
  }

  @computed
  get number() {
    return null;
  }

  @computed
  get nextSiblingIsRubric() {
    return !!(this.nextSibling && this.nextSibling.isRubric);
  }

  @computed
  get flatTreeItemsArray() {
    const data = [];
    if (this.tree) {
      data.push(this.tree);
    }
    this.flatItemsArray.forEach((item) => {
      data.push(item.tree);
    });
    return data;
  }

  @computed
  get removedFirstChildItemsArray() {
    if (!this.diffCompatitor || this.store.isPending || this.store.isDiffPending) {
      return [];
    }
    if (
      this.diffCompatitor.items && 
      this.diffCompatitor.items.length > 0 && 
      this.diffCompatitor.items[0].diffClass === "removed"
    ) {
      return this.diffCompatitor.items[0].flatItemsArray;
    }
    return [];
  }

  @computed
  get removedDescendantItemsArray() {
    if (this.nextSibling && this.nextSibling.diffClass === "removed") {
      return this.nextSibling.flatItemsArray;
    }
    if (this.diffCompatitor && this.diffCompatitor.nextSibling && this.diffCompatitor.nextSibling.diffClass === "removed") {
      return this.diffCompatitor.nextSibling.flatItemsArray;
    }
    if (!this.diffCompatitor || this.store.isPending || this.store.isDiffPending) {
      return [];
    }
    return [];
  }

  @computed
  get flatItemsArray() {
    const data = [this];
    if (this.removedFirstChildItemsArray && this.removedFirstChildItemsArray.length) {
      this.removedFirstChildItemsArray.forEach((item) => {
        if (item) {
          data.push(item);
        }
      });
    }
    this.idsArray.forEach((id) => {
      const item = this.getItemById(id);
      item.flatItemsArray && item.flatItemsArray.forEach((item) => {
        if (item) {
          data.push(item);
        }
      });
    });
    if (!this.store.isPending && !this.store.isDiffPending && this.removedDescendantItemsArray.length > 0) {
      this.removedDescendantItemsArray.forEach((item) => {
        if (item) {
          data.push(item);
        }
      });
    }
    return data;
  }
}

export default BaseBoxClass;
