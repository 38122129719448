import BaseRedmineModel from "./baseRedmineModel";

import { observable, computed, action } from "mobx";

/**
 * Модель категории. 
 *
 * @class CategoryModel
 */
class CategoryModel extends BaseRedmineModel {
  /**
   * Название категории
   *
   * @type String
   */
  @observable
  name = undefined;

  /**
   * Cоздание модели
   *
   * @param {Object} params параметры модели
   * @param {String} params.id id категории в Redmine
   * @param {String} params.name название  категории
   * @param {IssueStore} issueStore хранилище задач
   *
   * @retrun {CategoryModel}
   */
  static create({
    id,
    name
  }, issueStore) {
    return new CategoryModel({
      id,
      name
    }, issueStore);
  }

  constructor(params, issueStore) {
    super(params, issueStore);

    this.name = params.name;
  }

  /**
    * Вид модели
    * @return {String}
    */
  get kindModel() {
    return "CategoryModel";
  }

  /**
   * Обновить св-ва модели
   * @param {Object} params параметры модели
   * @param {String} params.name название трекера
   */
  @action
  update(data) {
    this.name = data.name || this.name;
  }

  /**
   * Строковое представление трекера
   *
   * @return {String}
   */
  @computed
  get title() {
    return this.name;
  }

  /**
   * @computed
   * Значение для конфигурационного файла.
   *
   * @return {Object} данные
   */
  get config() {
    return {
      id:   this.id,
      name: this.name
    };
  }
}

export default CategoryModel;
