import { observable, computed, action } from "mobx";
/**
 * Пользователь
 *
 * @type {User}
 */

class User {
  @observable uid;
  @observable class;
  @observable login;
  @observable email;
  @observable firstname;
  @observable type = "user";
  @observable lastname;
  @observable patronymic;
  @observable image;
  @observable groups = new Map();

  @observable newEmail;
  @observable newImage;
  @observable newFirstname;
  @observable newLastname;
  @observable newPatronymic;

  /**
   * Check if a `value` is a `User` instance.
   *
   * @param {Any} value
   * @return {Boolean}
   */

  static isUser(value) {
    return value instanceof User;
  }

  /**
   * Валидатор имени пользователя
   *
   * @param {String} name имя пользователя
   *
   * @return {Object} { isValid: true|false, hint: ""}
   */
  static validateFirstName(name = "") {
    const res = name && name.length >= 2;
    if (!res) {
      return {
        isValid: false,
        hint:    "Имя должно быть больше одного символа"
      };
    }
    return { isValid: true };
  }

  /**
   * Валидатор фамилии пользователя
   *
   * @param {String} name фамилия пользователя
   *
   * @return {Object} { isValid: true|false, hint: ""}
   */
  static validateLastName(name = "") {
    const res = name &&  name.length >= 2;
    if (!res) {
      return {
        isValid: false,
        hint:    "Фамилия должна быть больше одного символа"
      };
    }
    return { isValid: true };
  }

  /**
   * Валидатор отчества пользователя
   *
   * @param {String} name отчество пользователя
   *
   * @return {Object} { isValid: true|false, hint: ""}
   */
  static validatePatronymic(patronymic = "") {
    const res = patronymic && patronymic.length >= 2;
    if (!res) {
      return {
        isValid: false,
        hint:    "Отчество должно быть больше одного символа"
      };
    }
    return { isValid: true };
  }

  /**
   * Валидатор email пользователя
   *
   * @param {String} email пользователя
   * @param {UserStore} userStore хранилище для работы  с пользователями
   * @param {String} userUid uid текущего пользователя. Необходим, чтобы исключить текущего пользователя при проверке
   *
   * @return {Object} { isValid: true|false, hint: ""}
   */
  static validateEmail(email = "", userStore, userUid) {
    if (email === "") {
      return {
        isValid: false,
        hint:    "Пожалуйста укажите электронную почту пользователя"
      };
    }
    const res = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    if (!res) {
      return {
        isValid: false,
        hint:    "Неверный формат E-mail"
      };
    }
    if (!userStore) {
      return { isValid: true };  
    }

    const usersInAis = userStore.list.filter((user) => {
      if (!userUid) {
        return user.email === email;
      }
      
      return user.uid !== userUid && user.email === email; 
    });

    if (usersInAis.length > 0) {
      return {
        isValid: false,
        hint:    `Пользователь с E-mail="${email}" уже зарегистрирован в АИС. Пожалуйста, укажите другой E-mail!`
      };
    }

    return { isValid: true };
  }

  /**
   * Валидатор логина пользователя
   *
   * @param {String} login пользователя
   * @param {UserStore} userStore хранилище для работы  с пользователями
   *
   * @return {Object} { isValid: true|false, hint: ""}
   */
  static validateLogin(login = "", userStore) {
    const res = login && login.length >= 2;
    if (!res) {
      return {
        isValid: false,
        hint:    "Логин должен быть больше одного символа"
      };
    }
    if (!userStore) {
      return { isValid: true };  
    }
    const usersInAis = userStore.list.filter((user) => {
      return user.login === login;
    });

    if (usersInAis.length > 0) {
      return {
        isValid: false,
        hint:    `Пользователь с Логин="${login}" уже зарегистрирован в АИС. Пожалуйста, укажите другой Логин!`
      };
    }

    return { isValid: true };
  }

  /**
   * Cоздание модели
   *
   * @param {Object} data данные модели
   * @param {String} data.uid uid пользователя
   * @param {String} data.class class пользователя
   * @param {String} data.login login пользователя
   * @param {String} data.email email пользователя
   * @param {String} data.firstname имя пользователя
   * @param {String} data.lastname фамилия пользователя
   * @param {String} data.patronymic отчество пользователя
   * @param {String} data.image id файла в файловом хранилище на аватарку пользователя
   * @param {UserStore} store хранилище пользователей
   *
   * @retrun {User}
   */
  static create({
    uid,
    class: klass,
    login,
    email,
    firstname,
    lastname,
    patronymic,
    image
  }, store) {
    return new User({
      uid,
      class: klass,
      login,
      email,
      firstname,
      lastname,
      patronymic,
      image
    }, store);
  }

  constructor(data, store) {
    this.store = store;

    this.uid = data.uid;
    this.class = data.class;
    this.login = data.login;
    this.email = data.email;
    this.firstname = data.firstname;
    this.lastname = data.lastname;
    this.patronymic = data.patronymic;
    this.image = data.image;
  }

  @action
  update(data) {
    this.login = data.login || this.login;
    this.email = data.email || this.email;
    this.firstname = data.firstname || this.firstname;
    this.lastname = data.lastname || this.lastname;
    this.patronymic = data.patronymic || this.patronymic;
    this.image = data.image || this.image;
    this.uid = data.uid || this.uid;
  }

  @action
  undo() {
    this.newEmail = undefined;
    this.newImage = undefined;
    this.newFirstname = undefined;
    this.newLastname = undefined;
    this.newPatronymic = undefined;
    this.newPosition = undefined;
  }

  @action
  async save() {
    const data = await this.store.api.updateUser(this.uid, {
      email:      this.newEmail || this.email,
      firstname:  this.newFirstname || this.firstname,
      lastname:   this.newLastname || this.lastname,
      patronymic: this.newPatronymic || this.patronymic,
      image:      this.newImage
    });
    this.update(data);
    this.undo();
    this.store.setItem(null);
  }

  @action
  change(target, value) {
    switch (target) {
      case "firstname":
        this.newFirstname = value;
        break;
      case "lastname":
        this.newLastname = value;
        break;
      case "email":
        this.newEmail = value;
        break;
      case "patronymic":
        this.newPatronymic = value;
        break;
      case "imge":
        this.newImage = value;
        break;
      default:
        break;
    }
  }

  /**
   *   group{Group}
   */
  @action
  addGroup(group) {
    this.groups.set(group.uid, group);
  }

  @action
  async insertGroup(uid, withRequest) {
    if (withRequest) {
      await this.store.api.addMember(uid, [this.uid]);
    }
    const group = this.store.getItem(uid);
    if (group) {
      this.addGroup(group);
      group.addUser(this);
    }
  }

  @action
  deleteGroup(group) {
    this.groups.delete(group.id);
  }

  /**
   *   groupUid{uid}
   */
  @action
  async removeGroup(uid, withRequest) {
    if (withRequest) {
      await this.store.api.removeMember(uid, [this.uid]);
    }
    const group = this.store.getItem(uid);
    if (group) {
      this.deleteGroup(group);
      group.deleteUser(this);
    }
  }

  @computed
  get value() {
    return this.uid;
  }

  @computed
  get isMe() {
    return this.uid === this.store.rootStore.accountStore.uid;
  }

  @computed
  get label() {
    return this.shortName;
  }

  @computed
  get hasChanges() {
    return (
      this.newEmail || this.newImage || this.newFirstname || this.newLastname || this.newPatronymic || this.newPosition
    );
  }

  @computed
  get groupArray() {
    return Array.from(this.groups.values());
  }

  @computed
  get shortName() {
    if (this.firstname && this.patronymic && this.lastname) {
      return `${this.lastname}  ${this.firstname[0]}.${this.patronymic[0]}.`;
    } else {
      return this.lastname || this.firstname || this.patronymic;
    }
  }

  @computed
  get name() {
    return `${this.lastname ? `${this.lastname} ` : ""}${this.firstname ? `${this.firstname} ` : ""}${this.patronymic ||
      ""}`;
  }

  @computed
  get title() {
    return this.shortName;
  }
}

export default User;
