import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { observer } from "mobx-react";

import Target from "~/core/components/Target";
import useStores from "~/core/utils/useStores";

import infoToolContent from "~/core/components/InfoToolWindow/infoToolContent.js";

import "./css/index.scss";
import QAApi from "../api/QAApi";
import { Components } from "@ais3p/ui-framework";
import { SIDEPANEL_QA_REQ } from "../../../core/constants/SidePanels";
import QualityStatus from "./QualityStatus";
import AisIcon from "../../../core/components/AisIcon";

/**
 * @component
 * 
 * Боковая панель для проверки качества требований АИС
 * 
 * @param {Object} trackedItem активный элемент в АИС
 * @param {Boolean} hasTarget 
 */
const QualityAssuranceSidepanel = observer((props) => {
  const { hasTarget, trackedItem } = props;
  const { rootStore, objectStore } = useStores();

  const qaApi = useMemo(() => {
    return new QAApi(rootStore);
  }, []);

  const realItem = useMemo(() => {
    if (!trackedItem) {
      return null;
    }
    return objectStore.getVersion(trackedItem.uid, trackedItem.tool, trackedItem.version);
  }, [objectStore, trackedItem]);

  const [pending, setPending] = useState(false);
  const [result, setResult] = useState(null);
  
  const getQAResult = useCallback(async() => {
    if (!realItem || !realItem.plainText) {
      return;
    }
    setPending(true);
    const res = await qaApi.assureReq(realItem.plainText);
    setResult(res);
    setPending(false);
  }, [realItem, qaApi]);

  useEffect(() => {
    setResult(null);
  }, [trackedItem]);

  return (
    <div className="qa-sidepanel">
      <Target trackedItem={trackedItem} info={infoToolContent[SIDEPANEL_QA_REQ]} />
      <div className="qa-content">
        {!!result && (
          <div className="result-render">
            {Object.keys(result).map((key) => {
              return (
                <QualityStatus
                  key={key}
                  type={key}
                  data={result[key]}
                />
              );
            })}
          </div>
        )}
        {!result && (
          <div className="result-render">
            <div className="header"><span className="text-holder">Проверка не производилась</span></div>
          </div>
        )}
        <div className="buttons-holder">
          <Components.Button
            key="copy"
            text="Проверить"
            icon="check-M"
            isLoading={pending}
            onPress={getQAResult}
            color="action"
          />
        </div>
      </div>
    </div>
  );
});

QualityAssuranceSidepanel.propTypes = {
  trackedItem: PropTypes.object,   
  hasTarget:   PropTypes.bool
};


export default QualityAssuranceSidepanel;
