import { observer } from "mobx-react";
import React, { useCallback, useLayoutEffect, useMemo, useRef } from "react";

import Indicator from "../../../core/components/Indicator";
import TextItemIcon from "./TextItemIcon";

const Indent = observer(({ data, renderItem, setContextMenu, isHoverPreview }) => {
  const { items, uid, hasChunks, idsArray, isExpanded, scrollItemId, isFocusUid, diffClass, additionalClasses } = data;

  const itemsRender = useMemo(() => {
    return items.map((itemData) => {
      return renderItem(itemData);
    });
  }, [idsArray, items]);

  const onCreateChunk = useCallback(
    (e) => {
      e && e.stopPropagation();
      if (!hasChunks) {
        data.createFirstChunk();
      }
    },
    [hasChunks]
  );

  const onClickIcon = useCallback(async(e) => {
    e && e.stopPropagation && e.stopPropagation();
    if (!hasChunks) {
      await onCreateChunk();
    } else {
      data.setEditing();
    }
    data.setFocusUid();
  }, [data, hasChunks, isExpanded]);

  const element = useRef();

  useLayoutEffect(() => {
    if (
      uid &&
      scrollItemId &&
      uid === scrollItemId &&
      element &&
      element.current
    ) {
      setTimeout(() => {
        element.current && element.current.scrollIntoView(true);
      }, 200); // set timeout= DIRTY HACK 
      // TODO: get rid of setTimeout()
    }
  }, [uid, scrollItemId, element && element.current]);

  return (
    <div
      id={uid} 
      onClick={onClickIcon} 
      ref={element}
      className={`indent element ${additionalClasses} ${isHoverPreview ? "preview" : ""} ${diffClass}`}
    >
      <div className={`item-holder  ${isFocusUid ? "focus" : ""}`}>
        <Indicator data={data} />
        <TextItemIcon onClick={onClickIcon} setContextMenu={setContextMenu} data={data} />
        <div className="indent-body" onClick={onClickIcon}>
          {itemsRender}
          {!hasChunks && (
            <div onClick={onCreateChunk} className="placeholder">
              Нет содержимого
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default Indent;
