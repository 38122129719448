/**
 * Нвбор констант для сохранения конфигурации
 */
const MODULE_NAME_CFG = "issues";
const FILTERS_CFG = "filters";
const JOURNAL_CFG = "journal";
const TABLE_COLUMNS_CFG = "table-columns";
const COORD_LETTER_FIELDS_PIVOT_CFG = "coord-letter-fields-pivot";
const COORD_LETTER_FIELDS_ISSUES_CFG = "coord-letter-fields-issues";

export {
  MODULE_NAME_CFG,
  FILTERS_CFG,
  JOURNAL_CFG,
  TABLE_COLUMNS_CFG,
  COORD_LETTER_FIELDS_PIVOT_CFG,
  COORD_LETTER_FIELDS_ISSUES_CFG
};
