import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Components } from "@ais3p/ui-framework";
import { ISSUE_VALUE_SELECTABLE, getIssueValueLabel } from "../../constants/values";
import IssueStore from "../../stores/IssueStore";


/**
 * Компонент для отображения ячейки заголовка в табличном представлении списка задач
 * 
 * @param {String} id идентификатор колонки
 * @param {Boolean} isSelectionCol признак, что колонка для выбора записи
 * @param {IssueStore} store хранилище для работы с задачами
 */
const TableColHeader = observer(({ id, store }) => {
  // const [isAllItemsSelected, setIsAllItemsSelected] = useState();

  const isAllItemsSelected = useMemo(() => {
    return store.selectedUidsIssueMap.size > 0;
  }, [store.selectedUidsIssueMap.size]);

  const onSelectAllIssues = useCallback(async() => {
    await store.selectAllIssues(true);
    // setIsAllItemsSelected(b);
  }, [store]);

  const onDeselectAllIssues = useCallback(() => {
    store.deselectAllIssues();
    // setIsAllItemsSelected(false);
  }, [store]);

  const selCell = useMemo(() => {
    const name = isAllItemsSelected ? "checkbox-yes-S" : "checkbox-no-S";
    const onClick = isAllItemsSelected ? onDeselectAllIssues : onSelectAllIssues;
    const tooltip = isAllItemsSelected ? "Отменить выбор всех задач" : "Выбрать все задачи";
    return (
      <Components.Tooltip content={tooltip}>
        <Components.Icon
          className="select-issue" 
          name={name} 
          tooltip={tooltip}
          onClick={onClick}
        />
      </Components.Tooltip>
    );
  }, [
    isAllItemsSelected, 
    onSelectAllIssues,
    onDeselectAllIssues
  ]);

  return (
    <th
      className={`issues-table-column-header ${id}-header`}
    > 
      {id === ISSUE_VALUE_SELECTABLE && selCell }
      {id !== ISSUE_VALUE_SELECTABLE &&
        <span className="issues-table-column-header-text">
          {getIssueValueLabel(id)}
        </span>
      }
    </th>
  );
});

TableColHeader.propTypes = {
  id:             PropTypes.string.isRequired,
  isSelectionCol: PropTypes.bool,
  store:          PropTypes.instanceOf(IssueStore)
};


export default TableColHeader;
