import React, { useCallback } from "react";
import { observer } from "mobx-react";
import JsonViewer from "./JsonViewer";


/**
 * Компонент для отображения значения Payload записи в журнале
 *
 * @class Payload
 */
const Payload = observer(({ className }) => {
  return (
    <div className={`journal-item-payload ${className ? className : ""}`} />
  );
});

export default Payload;
