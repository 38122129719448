import { observer } from "mobx-react";
import React, { useCallback, useLayoutEffect, useRef } from "react";

import Indicator from "../../../core/components/Indicator";

import InlineFormula from "./InlineFormula";
import TextItemIcon from "./TextItemIcon";

const Formula = observer(({ data, renderItem, setContextMenu }) => {
  const { 
    uid, 
    inlineItem, 
    caption, 
    scrollItemId, 
    isFocusUid, 
    diffClass, 
    additionalClasses 
  } = data;
  const captionRender = caption && renderItem(caption);

  const onClickIcon = useCallback(() => {
    if (data.captionId) {
      data.setEditing();
    } else {
      data.createCaption();
    }
    data.setFocusUid();
  }, [data]);

  const element = useRef();

  useLayoutEffect(() => {
    if (
      uid &&
      scrollItemId &&
      uid === scrollItemId &&
      element &&
      element.current
    ) {
      setTimeout(() => {
        element.current && element.current.scrollIntoView(true);
      }, 200); // set timeout= DIRTY HACK 
      // TODO: get rid of setTimeout()
    }
  }, [uid, scrollItemId, element && element.current]);

  return (
    <div id={uid} ref={element} className={`formula element ${additionalClasses} ${diffClass}`}>
      <div className={`item-holder  ${isFocusUid ? "focus" : ""}`}>
        <Indicator data={data} />
        <TextItemIcon onClick={onClickIcon} setContextMenu={setContextMenu} data={data} />
        <div className="formula-body" onClick={onClickIcon}>
          <div className="formula-content">
            {inlineItem && <InlineFormula data={inlineItem} />}
          </div>
          {caption && captionRender}
        </div>
      </div>
    </div>
  );
});

export default Formula;
