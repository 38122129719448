import React, { useCallback, useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import classNames from "classnames";
import { colorShade } from "~/core/utils";
import useStores from "~/core/utils/useStores";
import { SHADE_BACKGROUND_COLOR, SHADE_BORDER_COLOR, SHADE_TEXT_COLOR } from "../../constants/colorShade";


/**
 * Компонент для отображения Приоритета в карточке Задача
 * 
 * @param {Object} props набор параметров
 * @param {String} params.label текст заголовка label
 * @param {String} params.placeholder текст placeholder
 * @param {String} params.className пользовательский className
 * @param {Array<Object>} params.options значения в выпадающем списке
 * @param {Object} params.value значение контрола
 * @param {String} params.name название контрола
 * @param {Boolean} params.useColor использовать ли цветовоую гамму
 * @param {Boolean} params.readOnly флаг только чтение
 * @param {Object} params.components набор доп. компонент для отображения выпадающего списка
 * @param {Function} params.onChange callback ф-я при изменении значения
 */
const PrioritiesField = (props) => {
  const {
    label = "Приоритет",
    placeholder = "Выберите",
    className,
    options,
    value,
    name,
    useColor = true,
    readOnly = false,
    components
  } = props;

  const { uiStore } = useStores();

  const [currentValue, setCurrentValue] = useState();

  useEffect(() => {
    setCurrentValue(options.filter((option) => {
      return option.value === value;
    })[0]);
  }, [value, options]);

  const onChange = useCallback((val) => {
    props.onChange && props.onChange(
      val.value,
      name
    );
  }, [props && props.onChange]);

  const customStyles = useMemo(() => {
    let res = {};
    if (!useColor) {
      return res;
    }

    res = {
      option: (provided, { data, isDisabled, isFocused, isSelected }) => {
        const clrName = uiStore.colors.getBaseColorName(uiStore.colors.getColorNameByHex(data.color));
        let backgroundColor = provided.backgroundColor;
        let textColor = data.color;

        if (isDisabled) {
          textColor = provided.color;
          backgroundColor = undefined;
        } else if (isSelected) {
          backgroundColor = clrName && uiStore.colors.getColorByName(`${clrName}1`) ||
                                        colorShade(data.color, SHADE_BACKGROUND_COLOR);
          textColor = clrName && uiStore.colors.getColorByName(`d${clrName}3`) ||
                        colorShade(data.color, SHADE_TEXT_COLOR);
        } else if (isFocused) {
          // backgroundColor = colorShade(data.color, SHADE_BACKGROUND_COLOR);
          // textColor = colorShade(data.color, SHADE_TEXT_COLOR);
        }

        return {
          ...provided,
          color: textColor,
          backgroundColor
        };
      }
    };
    if (currentValue && !!currentValue.color) {
      const colorName = uiStore.colors.getBaseColorName(uiStore.colors.getColorNameByHex(currentValue.color));
      const txtColor = colorName && uiStore.colors.getColorByName(`d${colorName}3`) || currentValue.color;
      res = {
        ...res,
        singleValue: (provided) => {
          return { ...provided, ...{
            color: `${txtColor}!important`
          } };
        }
      };
    }

    return res;
  }, [useColor, currentValue && currentValue.color]);
  
  const containerStyles = useMemo(() => {
    let res = {};
    if (!useColor) {
      return res;
    }

    if (currentValue && !!currentValue.color) {
      const colorName = uiStore.colors.getBaseColorName(uiStore.colors.getColorNameByHex(currentValue.color));
      const bgColor = colorName && uiStore.colors.getColorByName(`${colorName}1`) ||
                                    colorShade(currentValue.color, SHADE_BACKGROUND_COLOR);
      const borderColor = colorName && uiStore.colors.getColorByName(`${colorName}3`) ||
                                    colorShade(currentValue.color, SHADE_BORDER_COLOR);
      res = {
        backgroundColor: bgColor,
        borderColor
      };
    }

    return res;
  }, [useColor, currentValue && currentValue.color]);
  
  return (
    <div className={"ais-field-container select theme-default default"}>
      {label && <div className={"ais-field-label select"}>{label}</div>}
      <div 
        className={"ais-field"}
        style={containerStyles}
      >
        <div className={"ais-field-holder select"}>
          <Select
            className={classNames("ais-select priorities-field", className, {
              "read-only": readOnly
            })}
            styles={customStyles}
            menuPortalTarget={document.body}
            placeholder={placeholder}
            // styles={{ menuPortal: (base) => {
            //   return { ...base, zIndex: 400, ...customStyles };
            // } }}
            classNamePrefix="ais-select"
            value={currentValue}
            isDisabled={readOnly}
            isLoading={false}
            isClearable={false}
            isRtl={false}
            isSearchable={false}
            label={label}
            name={name}
            options={options}
            components={components}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
};

PrioritiesField.propTypes  = {
  label:       PropTypes.string,
  placeholder: PropTypes.string,
  className:   PropTypes.string,
  options:     PropTypes.arrayOf(Object),
  value:       PropTypes.any,
  name:        PropTypes.string,
  useColor:    PropTypes.bool,
  readOnly:    PropTypes.bool,
  components:  PropTypes.object,
  onChange:    PropTypes.func
};

export default PrioritiesField;
