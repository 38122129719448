import React, { useCallback } from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import { Components } from "@ais3p/ui-framework";
import { IssueModel } from "../../models";
import IssueStore from "../../stores/IssueStore";


/**
 * Компонент для отображения ячейки в табличном представлении списка задач для выбора задачи
 * 
 * @param {IssueModel} issue модель задачи
 * @param {IssueStore} store хранилище для работы с задачами
 * @param {Boolean} isSelectionCol признак, что колонка для выбора записи
 */
const TableSelectionCell = observer(({ issue, store }) => {
  const isSelected = store.selectedUidsIssueMap.has(issue.uid);

  const onToggleState = useCallback(() => {
    if (isSelected) {
      store.removeUidSelectedIssue(issue.uid);
    } else {
      store.addUidSelectedIssue(issue.uid);
    }
  }, [isSelected]);
  
  // const icon = useMemo(() => {
  //   const name = isSelected ? "checkbox-yes-S" : "checkbox-no-S";
  //   return <Components.Icon className="" name={name} onClick={onToggleState} />;
  // }, [isSelected, onToggleState]);
  
  return (
    <Components.Icon 
      className="select-issue" 
      name={isSelected ? "checkbox-yes-S" : "checkbox-no-S"} 
      onClick={onToggleState}
    />
  );
});

TableSelectionCell.propTypes = {
  issue:          PropTypes.instanceOf(IssueModel).isRequired,
  isSelectionCol: PropTypes.bool,
  columnId:       PropTypes.string,
  store:          PropTypes.instanceOf(IssueStore).isRequired
};

export default TableSelectionCell;
