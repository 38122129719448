import Server from "~/core/api/server";

/**
 * Api запросов к сервису для работы с Видами и Аттрибутами
 */
class QAApi extends Server {
  methods = {
    assureReq: {
      url:             this.getServiceUrl("ai-req-quality", "post_plain_text"),
      method:          "POST",
      noJSONStringify: true,
      contentType:     "text/plain",
      textProcess:     "Проверка качества требования...",
      textSuccess:     null,
      textError:       this.template`Во время проверки качества требования произошла ошибка: ${0}`
    }
  };

  async assureReq(text) {
    try {
      return await this.request("assureReq", text);
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }
}

export default QAApi;
