import React from "react";
import PropTypes from "prop-types";

import { observer } from "mobx-react";

/**
 * @component
 * 
 * Компонент одного критерия качества
 * 
 * @param {Object} data 
 * @param {String} type 
 */
const QualityStatus = observer(({ data, type }) => {
  const labels = {
    completeness:   "Полнота",
    correctness:    "Корректность",
    indivisibility: "Неделимость",
    unambiguity:    "Однозначность"
  };
  return (
    <div className="service">
      <div className="row">
        <div className={`status ${data.status === 0 ? "alive" : "dead"}`}></div>
        <div className="name">{labels[type]}</div>
      </div>
      {data.status !== 0 && (
        <div className="reason">
          {data.possible_explanation || "Причина не известна"}
        </div>
      )}
    </div>
  );
});

QualityStatus.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string
};


export default QualityStatus;
