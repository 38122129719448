import React, { useCallback } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";

import { Modal, Components } from "@ais3p/ui-framework";
import Wizard from "../components/Wizard";

import "./css/index.scss";

/**
 * Мастер создания объектов в модальном окне
 * Каждый шаг здесь, это переданный компонент в `children`
 */
const WizardTool = observer(({
  /**
   * Иконка Wizard
   * 
   * @type {String}
   * @default "wizard-M"
   */
  icon = "wizard-M",

  /**
   * Заголовок Wizard
   * 
   * @type {String}
   */
  title,

  /**
   * Вложенные панели, которые будут являться шагами в Wizard
   * 
   * @type {Array<Element>}
   */
  children,

  /**
   * Callback ф-я на завершение работы Wizard
   * 
   * @type {Function}
   */
  onDone, 

  /**
   * Callback ф-я на отмену работы Wizard. 
   * Это нажаие кнопки `Отменить` или нажатие клавиши Esc
   * 
   * @type {Function}
   */
  onCancel,

  /**
   * Признак загрузки даных
   * 
   * @type {Boolean}
   */
  isLoading,

  /**
   * Пользовательский className
   * 
   * @type {String}
   */
  className
}) => {
  const onDoneFn = useCallback((data) => {
    onDone && onDone(data);
  }, []);

  const onCancelFn = useCallback(() => {
    onCancel && onCancel();
  }, []);

  const onKeyPressEnter = useCallback(async() => {
    // onDone && onDone(data);
  }, [onDone]);

  const onKeyPressEsc = useCallback(() => {
    onCancel && onCancel();
  }, [onCancel]);

  return (
    <Modal.Window
      name="wizard"
      icon={icon}
      title={title}
      show={true}
      onKeyPressEnter={onKeyPressEnter}
      onKeyPressEsc={onKeyPressEsc}
    >
      <div className={classNames("wizard-tool-content2", className)}>
        {isLoading && <Components.Preloader size={3} className="wizard-preloader" />}
        <Wizard
          isLoading={isLoading}
          onDone={onDoneFn}
          onCancel={onCancelFn}
        >
          {children}
        </Wizard>
      </div>
    </Modal.Window>
  );
}
);

export default WizardTool;
