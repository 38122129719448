import BaseRedmineModel from "./baseRedmineModel";

import { observable, computed, action } from "mobx";

/**
 * Модель статуса задачи
 *
 * @class StatusModel
 */
class StatusModel extends BaseRedmineModel {
  /**
   * Название статуса
   *
   * @type String
   */
  @observable
  name = undefined;

  /**
   * Статус, который закрывает задачу
   *
   * @type Boolean
   */
  @observable
  isClosed = undefined;

  /**
   * Статус, который выставляется задаче по умолчанию
   *
   * @type Boolean
   */
  @observable
  isDefault = undefined;

  /**
   * Цвет для отображения
   *
   * @type String
   */
  color = undefined;

  /**
   * Валидатор названия статуса
   *
   * @param {String} name название статуса
   *
   * @return {Object} { isValid: true|false, hint: ""}
   */
  static validateName(name = "") {
    const res = name.length >= 2;
    if (!res) {
      return {
        isValid: false,
        hint:    "Название должно быть больше одного символа"
      };
    }
    return { isValid: true };
  }

  /**
   * Cоздание модели
   *
   * @param {Object} params параметры модели
   * @param {String} params.id id названия статуса задачи в Redmine
   * @param {String} params.name название статуса задачи
   * @param {String} params.isClosed может ли статус зарыть задачу
   * @param {String} params.isDefault выставялется ли статус задаче по умолчанию
   * @param {String} params.color цвет для отображения
   * @param {IssueStore} issueStore хранилище задач
   *
   * @retrun {StatusModel}
   */
  static create({
    id,
    name,
    isClosed,
    isDefault,
    color
  }, issueStore) {
    return new StatusModel({
      id,
      name,
      isClosed,
      isDefault,
      color
    }, issueStore);
  }

  constructor(params, issueStore) {
    super(params, issueStore);

    this.name = params.name;
    this.isClosed = params.isClosed;
    this.isDefault = params.isDefault;
    this.color = params.color;
  }

  /**
   * Вид модели
   * @return {String}
   */
  get kindModel() {
    return "statusModel";
  }

  /**
   * Обновить св-ва модели
   * @param {Object} params параметры модели
   * @param {String} params.name название проекта
   * @param {String} params.isClosed может ли статус зарыть задачу
   * @param {String} params.isDefault выставялется ли статус задаче по умолчанию
   * @param {String} params.color цвет для отображения
   */
  @action
  update(data) {
    this.name = data.name || this.name;
    this.isClosed = data.isClosed || this.isClosed;
    this.isDefault = data.isDefault || this.isDefault;
    this.color = data.color || this.color;
  }

  /**
   * Строковое представление статуса
   *
   * @return {String}
   */
  @computed
  get title() {
    return this.name;
  }

  /**
   * @computed
   * Значение для конфигурационного файла.
   *
   * @return {Object} данные
   */
  get config() {
    return {
      id:        this.id,
      name:      this.name,
      color:     this.color,
      isClosed:  this.isClosed,
      isDefault: this.isDefault
    };
  }
}

export default StatusModel;
