import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { Components } from "@ais3p/ui-framework";
import useStores from "~/core/utils/useStores";
import UserAvatar from "~/modules/users/components/avatar";

const AccountButton = observer(() => {
  const { accountStore, uiStore, layoutStore } = useStores();
  
  const onDoLogout = useCallback(async() => {
    uiStore.setConfirmPending(true);
    try {
      await accountStore.performLogout();
      uiStore.hideConfirm();
    } finally {
      uiStore.setConfirmPending(false);
    }
  }, []);

  const onCancelLogout = useCallback(() => {
    uiStore.hideConfirm();
  }, []);

  const onLogout = useCallback(() => {
    if (uiStore.canLogout) {
      uiStore.setConfirm({
        icon:    "logout-M",
        title:   "Выход из системы",
        content: "Вы действительно хотите выйти?",
        buttons: [
          <Components.Button
            key="exit"
            text="Да"
            icon="logout-M"
            onPress={onDoLogout}
            color="positive"
            // isPendingTasks - условие, чтобы у пользователя не было
            // возможности нажать `Ok`, если еще идут асинхронные запросы.
            // Такое например возможно, если пользователь делал редакцию в `InputEditor` и после
            // нажал на выход из системы. В этот момент проиcходит событие `onBlur` и идет запрос
            // на принятие редакции - T3251
            isLoading={uiStore.isPendingTasks || uiStore.confirmIsPending}
            // isLoading={isPending}
          />,
          <Components.Button
            key="cancel"
            text="Отмена"
            icon="cancel-M"
            onPress={onCancelLogout}
            isLoading={uiStore.confirmIsPending}
            color="dark"
          />
        ],
        onKeyPressEsc:   onCancelLogout,
        onKeyPressEnter: onDoLogout
      });
    }
  }, [uiStore.canLogout, uiStore.isPendingTasks, uiStore.confirmIsPending, onCancelLogout, onDoLogout]);

  const onShowProfile = useCallback(() => {
    const tool = {
      icon:      "user-M",
      component: "userProfile"
    };
    const item = {
      name:  `Профиль ${accountStore.user && accountStore.user.shortName}`,
      id:    accountStore.user && accountStore.user.uid,
      props: {
        id:   accountStore.user && accountStore.user.uid,
        name: accountStore.user && accountStore.user.shortName
      }
    };
    layoutStore.open({ ...item, ...tool });
  }, []);

  return (
    <div className="app-user">
      <div className="app-user-avatar">
        <UserAvatar
          className="image"
          fileId={accountStore.user && accountStore.user.image}
        />
        <div className="app-user-menu">
          <Components.Tooltip 
            content={accountStore.user && accountStore.user.shortName}
            placement="right"
          >
            <div className="app-user-menu-item">
              <Components.Button
                size={3}
                // text={accountStore.user && accountStore.user.shortName}
                text="Профиль"
                onPress={onShowProfile}
                icon={"user-M"}
              />
            </div>
          </Components.Tooltip>
          <div className="spacer" />
          <div className="app-user-menu-item">
            <Components.Button
              size={3}
              text="Выйти"
              onPress={onLogout}
              icon="logout-M"
              isLoading={uiStore.isPendingTasks || uiStore.confirmIsPending}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default AccountButton;
