import { action, observable, runInAction, computed } from "mobx";

import Api from "../api/usersApi";
import User from "../models/User";
import Group from "../models/Group";
import RedmineUser from "~/modules/issues/models/userModel";
// import RedmineUser from "~/modules/issues/models/baseRedmineModel";

const arrayDiff = function(a, b) {
  return a.filter((i) => {
    return b.indexOf(i) < 0;
  });
};
/**
 * Глобальное хранилище пользователей.
 */
export default class UserStore {
  @observable rootStore = false;
  @observable userFormIsVisible = false;
  @observable groupFormIsVisible = false;
  @observable passFormIsVisible = false;
  @observable chooseItemIsVisible = false;

  @observable isPending = false;
  @observable users = new Map(); // список пользователей
  @observable groups = new Map(); // список пользователских групп
  @observable redmineUsers = new Map(); // список пользователей в Redmine
  @observable selectedType = "user";
  @observable selectedId;
  @observable systemUid; // uid системы

  /**
   * @type {String}
   * 
   * Регулярное выражение для проверки пароля
   * 
   * @default /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[@&#])[a-zA-Z0-9@&#]{8,}$/i
   * - ^ – начало строки.
   * - (?=.*?[A-Z]) – положительный просмотр вперед, чтобы убедиться, что строка содержит хотя бы одну заглавную букву.
   * - (?=.*?[0-9]) – проверка наличия хотя бы одной цифры.
   * - (?=.*?[@&#]) – проверка наличия одного из специальных символов (@, # или &).
   * - [a-zA-Z0-9@&#]{8,} – соответствие любому количеству букв (строчных и прописных), 
   *   цифр и указанных специальных символов длиной от 8 символов и больше.
   * - $ – конец строки.
   */
  @observable
  passwordRegExp = /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[@&#])[a-zA-Z0-9@&#]{8,}$/i;

  /**
   * 
   * @type {String} 
   * 
   * Строковое описание формата пароля. Данное сообщение будет отображаться пользователю, 
   * если не прошла валидация пароля по регулярному выражению
   */
  @observable
  // eslint-disable-next-line max-len
  passwordRegExpDescription = "Пароль должен состоять не менее, чем из 8 символов (чем длиннее пароль, тем лучше). Используйте сочетание по крайней мере 1 заглавной буквы, 1 цифры и 1 специального символа (#, @, &)";


  constructor(rootStore) {
    this.rootStore = rootStore;
    this.api = new Api(rootStore);
  }

  @computed
  get list() {
    return Array.from(this.users.values());
  }

  @computed
  get currentId() {
    return this.selectedId || null;
  }

  @computed
  get currentItem() {
    return this.getItem(this.currentId);
  }

  @computed
  get type() {
    return this.selectedType || null;
  }

  @computed
  get groupList() {
    return Array.from(this.groups.values());
  }

  /**
   * @computed
   *
   * Список пользователей Redmine
   *
   * @return {Array<RedmineUserModel>}
   */
  @computed
  get redmineUserList() {
    return Array.from(this.redmineUsers.values());
  }

  @computed
  get chooserItemsArray() {
    if (!this.currentItem) {
      return [];
    }
    if (this.currentItem.type === "user") {
      return arrayDiff(
        Array.from(this.groups.values()),
        this.currentItem.groupArray
      );
    } else if (this.currentItem.type === "group") {
      return arrayDiff(
        Array.from(this.users.values()),
        this.currentItem.userArray
      );
    }
    return [];
  }

  @action
  setIsPending(val) {
    this.isPending = val;
  }

  @action
  showChooser() {
    this.chooseItemIsVisible = true;
  }

  @action
  changePass(uid) {
    this.passFormIsVisible = uid;
  }

  @action
  async changePassword(data) {
    if (this.passFormIsVisible) {
      await this.api.updatePass(this.passFormIsVisible, data);
      this.hideForm();
    }
  }

  @action
  async init() {
    this.isPending = true;
    try {
      await this.loadUsers(true);
      await this.loadGroups();
    } catch (e) {
      this.onError(e);
    } finally {
      runInAction(() => {
        this.isPending = false;
      });
    }
  }

  /**
   * Получить пользователя
   *
   * @param  {String} uid пользователя
   */
  @action
  get(uid) {
    return this.users.get(uid);
  }

  @action
  chooseItem(uid) {
    const withRequest = true;
    if (this.currentItem.type === "user") {
      this.currentItem.insertGroup(uid, withRequest);
    } else if (this.currentItem.type === "group") {
      this.currentItem.insertUser(uid, withRequest);
    }
  }

  @action
  async createUser(data) {
    const userData = await this.api.createUser(data);
    this.addUser(userData);
    this.hideForm();
  }

  @action
  async createGroup(data) {
    const groupData = await this.api.createGroup(data);
    this.addGroup(groupData);
    this.hideForm();
  }

  @action
  delete(uid) {
    if (!this.currentItem) {
      if (this.type === "user") {
        this.deleteUser(uid);
      } else if (this.type === "group") {
        this.deleteGroup(uid);
      }
    } else {
      const withRequest = true;
      if (this.type === "user") {
        this.currentItem.removeGroup(uid, withRequest);
      } else if (this.type === "group") {
        this.currentItem.removeUser(uid, withRequest);
      }
    }
  }

  @action
  async deleteUser(uid) {
    await this.api.deleteUser(uid);
    this.removeUser(uid);
  }

  @action
  async deleteGroup(uid) {
    await this.api.deleteGroup(uid);
    this.removeGroup(uid);
  }

  @action
  showForm() {
    if (this.type === "user") {
      this.userFormIsVisible = true;
    } else if (this.type === "group") {
      this.groupFormIsVisible = true;
    }
  }

  @action
  hideForm() {
    this.userFormIsVisible = false;
    this.groupFormIsVisible = false;
    this.chooseItemIsVisible = false;
    this.passFormIsVisible = false;
  }

  /**
   * Выбрать user/group
   *
   * @param  {String} uid пользователя
   */
  @action
  setItem(uid) {
    this.setId(uid);
    const item = this.getItem(uid);
    if (item) {
      this.setType(item.type);
    }
  }

  /**
   * Выбрать user/group
   *
   * @param  {String} uid пользователя
   */
  @action
  setId(uid) {
    this.selectedId = uid;
  }

  /**
   * Выбрать user/group
   *
   * @param  {String} uid пользователя
   */
  @action
  setType(type) {
    this.selectedType = type;
  }

  /**
   * Получить группу
   *
   * @param  {String} uid группы
   */
  @action
  getGroup(uid) {
    return this.groups.get(uid);
  }

  /**
   * Получить item
   *
   * @param  {String} uid группы
   */
  @action
  getItem(uid) {
    return this.get(uid) || this.getGroup(uid);
  }

  /**
   * Получить пользователя Redmine из списка
   *
   * @param  {String} id пользователя в Redmine
   */
  getRedmineUser(id) {
    return this.redmineUsers.get(id);
  }

  /**
   * Список пользователей Redmine, у которых есть связь с пользователями в АИС
   *
   * @return {Array<RedmineUserModel>}
   */
  @computed
  get redmineLinkedUserList() {
    return this.redmineUserList.filter((user) => {
      return user.isLinked;
    });
  }


  /**
   * Получить пользователя Redmine из списка по uid пользователя в АИС
   *
   * @param  {String} uid пользователя в АИС
   */
  getRedmineUserByUid(uid) {
    return this.redmineLinkedUserList.filter((user) => {
      return String(user.uid) === String(uid);
    })[0];
  }

  /**
   * Загрузить список пользователей
   *
   * @param{Boolean} withGroups загрузить пользователей со списком групп, которым они принадлежат
   *
   * @return {Promise}
   */
  @action
  async loadUsers(withGroups = false) {
    try {
      const data = await this.api.loadUsers(withGroups);
      (data || []).forEach((item) => {
        this.addUser(item);
      });
      return data;
    } catch (ex) {
      this.onError(ex);
    }
  }

  /**
   * Загрузить список групп
   *
   * @return {Promise}
   */
  @action
  async loadGroups(withMembers = false) {
    try {
      const data = await this.api.loadGroups(withMembers);
      const groups = [];
      (data || []).forEach((item) => {
        groups.push(this.addGroup(item));
      });
      await Promise.all(groups);
      return data;
    } catch (ex) {
      this.onError(ex);
    }
  }

  /**
   * Добавить пользователя
   *
   * @param  {Object} props данные пользователя
   * @return {User}
   */
  @action
  addUser(props) {
    const u = User.create(props || {}, this);

    this.users.set(u.uid, u);

    const { groups } = props;
    if (groups && Array.isArray(groups)) {
      groups.forEach((gr) => {
        u.addGroup(Group.create(gr, this));
      });
    }
    return u;
  }

  /**
   * @action
   *
   * Добавить пользователя в список пользователей
   *
   * @param  {Object} props св-ва пользователя
   * @return {RedmineUser}
   */
  addRedmineUser(props = {}) {
    const u = RedmineUser.create(props, this);

    this.redmineUsers.set(u.redmineId, u);

    return u;
  }

  /**
   * Добавить группу
   *
   * @param  {Object} props данные группы
   * @return {Group}
   */
  @action
  async addGroup(props) {
    const gr = Group.create(props || {}, this);

    const { members } = props;
    if (members && Array.isArray(members)) {
      members.forEach((u) => {
        gr.addUser(User.create(u, this));
      });
    }
    this.groups.set(gr.uid, gr);
    return gr;
  }

  /**
   * Обновить свойства пользователя
   *
   * @param  {String} uid пользователя
   * @param  {Object} props свойства пользователя
   * @return {User}
   */
  @action
  updateUser(uid, props) {
    const user = this.get(uid);
    if (user) {
      user.update(props);
    }
  }

  /**
   * Удалить пользователя
   *
   * @param  {String} uid пользователя
   */
  @action
  removeUser(uid) {
    this.users.delete(uid);
  }

  /**
   * Удалить группу
   *
   * @param  {String} uid группы
   */
  @action
  removeGroup(uid) {
    this.groups.delete(uid);
  }

  /**
   * Отправить запрос для загрузки в хранилище файла аватарки пользователя
   *
   * @param  {String} uid пользователя
   * @param  {File} file файл аватари пользователя
   * @return {String} uid файла
   */
  async uploadAvatar(userUid, file) {
    this.setIsPending(true);
    try {
      const data =  await this.api.uploadAvatar(file);
      const fileItem = data[0] || {}; // берем только первый файл
      return fileItem.id;
    } catch (ex) {
      this.onError(ex);
    } finally {
      this.setIsPending(false);
    }

    return undefined;
  }

  /**
   * Изменить пароль у текущего пользователя
   * 
   * @param {String} userUid uid пользователя
   * @param {String} oldPassword старый пароль
   * @param {String} newPassword новый пароль
   * 
   * @returns{Boolean} признак удачности смены пароля
   */
  async updatePassword(userUid, oldPassword, newPassword) {
    this.setIsPending(true);
    try {
      await this.api.updatePass(userUid, { old: oldPassword, new: newPassword });
      return true;
    } catch (ex) {
      this.onError(ex);
    } finally {
      this.setIsPending(false);
    }
    return false;
  }

  /**
   * Отправить запрос для сохранения данных пользователя
   *
   * @param  {String} uid пользователя
   * @param  {Object} userData данные пользователя
   * @param {Boolean} inRedmineToo признак необходимости обновить данные пользователя еще и в Redmine
   * @return {Boolean}
   */
  async saveUser(userUid, userData, inRedmineToo = true) {
    this.setIsPending(true);
    try {
      const resp = await this.api.updateUser(userUid, userData);
      this.updateUser(userUid, resp);
      if (inRedmineToo) {
        await this.api.updateUserInRedmine(userUid, { 
          firstname: resp.firstname, 
          lastname:  resp.lastname, 
          email:     resp.email 
        });
      }
      return this.get(userUid);
    } catch (ex) {
      this.onError(ex);
    } finally {
      this.setIsPending(false);
    }
    return null;
  }

  /**
   * Обновить пользователя в Redmine
   *
   * @param  {String} uid пользователя
   * @param  {Object} data данные пользователя
   * @param  {String} data.email email пользователя
   * @param  {String} data.firstname имя пользователя
   * @param  {String} data.lastname фамилия пользователя
   * @param  {String} data.login login пользователя
   * @param  {String} data.password пароль пользователя
   *
   * @return {Boolean}
   */
  async updateUserInRedmine(userUid, data) {
    this.setIsPending(true);
    try {
      await this.api.updateUserInRedmine(userUid, data);
      return true;
    } catch (ex) {
      this.onError(ex);
    } finally {
      this.setIsPending(false);
    }

    return false;
  }

  /**
   * Загрузить список пользователей
   *
   * @return {Promise}
   */
  async loadRedmineUsers() {
    try {
      const data = await this.api.loadRedmineUsers();
      (data || []).forEach((item) => {
        this.addRedmineUser(item);
      });
      return data;
    } catch (e) {
      this.onError(e);
    }
  }

  /**
   * Создать нового пользователя в Redmine по данным, полученным из системы АИС по `uid` пользователя
   *
   * @param  {String} uid пользователя
   *
   * @return {Boolean}
   */
  async createUserInRedmine(userUid) {
    this.setIsPending(true);
    try {
      const data = await this.api.createUserInRedmine(userUid);
      return RedmineUser.create(data);
    } catch (ex) {
      this.onError(ex);
    } finally {
      this.setIsPending(false);
    }

    return undefined;
  }

  /**
   * @action
   *
   * Задать требования к паролю
   *
   * @param  {data} data дынные
   */
  setPasswordRequirements(data) {
    if (data && data.hasOwnProperty("regExp")) {
      this.passwordRegExp = new RegExp(data.regExp);
    }
    if (data && data.hasOwnProperty("regExpDescription")) {
      this.passwordRegExpDescription = data.regExpDescription;  
    }
  }

  
  /**
   * Валидатор пароля пользователя
   *
   * @param {String} password пароль пользователя
   *
   * @return {Object} { isValid: true|false, hint: ""}
   */
  validatePassword(password = "") {
    if (password === "") {
      return {
        isValid: false,
        hint:    "Пожалуйста задайте пароль для входа в систему"
      };
    }
    const res = password.match(this.passwordRegExp);
    if (!res) {
      return {
        isValid: false,
        hint:    "Неверный формат пароля"
      };
    }
    return { isValid: true };
  }

  /**
   * Очистить список
   *
   */
  @action
  clear() {
    this.users.clear();
    this.groups.clear();
  }

  @action
  onError(error) {
    this.rootStore.onError(error);
  }

  destroy() {
    this.clear();
  }
}
