import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
// import { Field } from "@ais3p/ui-framework";

import { useWizard } from "~/modules/wizard2";
import IssueStore from "../../stores/IssueStore";
import CustomField from "../customFields/CustomField";
import CoordLetterNumberField from "./CoordLetterNumber";
import { CoordTemplateModel } from "../../models";

/**
 * Шаг первый  в Wizard создания КП
 * Задание 
 * @returns 
 */
const Step2 = ({ store, template }) => {
  const {
    // stepData: template, // здесь передали модель выбранног шаблона
    handleStep,
    setStepData,
    setIsValid
  } = useWizard();

  const [values, setValues] = useState({});

  const isValid = useMemo(() => {
    if (!template) {
      return false;
    }
    return template && template.fields && template.fields.every((field) => {
      const res = field.validateValue(values[field.id]);
      return res.isValid;
    });
  }, [values]);

  useEffect(() => {
    setIsValid(isValid);
  }, [isValid, template, values]);

  handleStep(() => {
    template.updateFieldsValue(values);
    setStepData(template);
  });

  /**
   * Callback на изменение поля значения
   * 
   * @params {Any} value значение переменной
   * @params {String} name имя переменной
   * @params {String} type тип переменной
   */
  const onFieldChange = useCallback((value, name) => {
    setValues((state) => {
      return {
        ...state,
        [name]: value
      }; 
    });
  }, [template]);

  return (
    <div className="coord-letter-step">
      <div className="coord-letter-step-header">
        <h2>Заполните поля шаблона КП </h2>
      </div>
      <div className="coord-letter-step-body">
        <div className="data-content">
          {template && template.fields && 
          template.fields.map((field) => {
            if (field.fieldFormat === "coordLetterNumber") {
              return (
                <CoordLetterNumberField 
                  key="number"
                  customField={field}
                  template={template}
                  value={values[field.id]}  
                  store={store}
                  onChange={onFieldChange}
                />
              );
            }
            return (
              <CustomField
                key={field.id}
                customField={field}
                value={values[field.id]}
                store={store}
                readOnly={false}
                onChange={onFieldChange}
              />
            );
          })
          }
        </div>
      </div>
    </div>
  );
};

Step2.propTypes = {
  store:    PropTypes.instanceOf(IssueStore).isRequired,
  template: PropTypes.instanceOf(CoordTemplateModel).isRequired
};

export default Step2;
