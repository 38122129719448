import { Components, Dnd, Modal, Notification } from "@ais3p/ui-framework";
import { observer } from "mobx-react";
import React, { Fragment, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";

import { DND_EDITORITEM_TYPE, DND_FILE_TYPE } from "~/core/constants/DnD";
import AisIcon from "~/core/components/AisIcon";
import PlusPreview from "./PlusPreview";
import { CLS_TEXT_PICTURE } from "../../../core/constants/Classes";
import DataStore from "../stores/DataStore";

const FlatItem = observer(({ renderItem, item, dataStore, isPreview, canDropSelf, asParentDropZone }) => {
  const renderFlatItem = useCallback(
    (item) => {
      return <FlatItem item={item} renderItem={renderItem} dataStore={dataStore} />;
    },
    [renderItem, dataStore]
  );

  const itemRender = useMemo(() => {
    return renderItem(item, renderFlatItem);
  }, [item, renderItem, renderFlatItem]);

  const { isLongList } = dataStore;

  const element = useRef();

  const [isIntersecting, setIsIntersecting] = useState(false);
  const [isFirstCall, setIsFirstCall] = useState(true);
  const [hoverItem, setHoverItem] = useState(null);
  const [canDropResult, setCanDropResult] = useState(false);

  const onMouseLeave = useCallback(() => {
    item.setExpanded(false);
  }, [item, setHoverItem]);

  const processIntersection = useCallback(
    (entries) => {
      const intersecting = entries[0] && entries[0].isIntersecting;
      if (isFirstCall) {
        if (element && element.current) {
          const rect = element.current.getBoundingClientRect();
          item.setHeight(rect.height);
        }

        if (isIntersecting !== intersecting) {
          setIsIntersecting(intersecting);
        }
        setIsFirstCall(false);
        return false;
      }
      if (isIntersecting !== intersecting) {
        setIsIntersecting(intersecting);
        if (intersecting) {
          item.setBoundingIndex();
        }
      }
    },
    [isIntersecting, isFirstCall, item]
  );

  const observer = useMemo(() => {
    return new IntersectionObserver(processIntersection);
  }, [processIntersection]);

  const sensor = useMemo(() => {
    // if (isLongList) {
    //   return false;
    // }
    // return new ResizeObserver((entries) => {
    //   const rect =
    //     entries[0] &&
    //     entries[0].target &&
    //     entries[0].target.getBoundingClientRect();
    //   if (rect && isIntersecting) {
    //     window.requestAnimationFrame(() => {
    //       item.setHeight(rect.height);
    //     });
    //   }
    // });
  }, [item, isIntersecting]);

  useEffect(() => {
    if (element && element.current) {
      if (sensor) {
        sensor.observe(element.current);
      }
      if (observer) {
        observer.observe(element.current);
      }
    }
    return () => {
      sensor && sensor.disconnect();
      observer && observer.disconnect();
    };
  }, [sensor, observer, element && element.current]);

  // useLayoutEffect(() => {
  //   if (element && element.current) {
  //     const rect = element.current.getBoundingClientRect();
  //     item.setHeight(rect.height);
  //   }
  // }, [item]);

  const [dragItem, setDragItem] = useState(null);
  const [isDndPending, setIsDndPending] = useState(false);
  const [isDndDialogVisible, setIsDndDialogVisible] = useState(false);
  const [dndTargets, setDndTargets] = useState(null);
  const [targetIndex, setTargetIndex] = useState(null);

  const onConfirmDnd = useCallback(async() => {
    setIsDndPending(true);
    let target = dndTargets[targetIndex];
    if (!(target && target.ancorId)) {
      target = item;
    }

    await dataStore.move(dragItem, target);
    onCancelDnd();
  }, [dataStore, dragItem, dndTargets, targetIndex, item]);

  const onCancelDnd = useCallback(() => {
    setIsDndDialogVisible(false);
    setDragItem(null);
    setIsDndPending(false);
    setDndTargets(null);
    setTargetIndex(null);
  }, []);

  const dndDialogButtons = useMemo(() => {
    return [
      <Components.Button
        key="add"
        text="Подтвердить"
        icon="ok-M"
        onPress={onConfirmDnd}
        isDisabled={(targetIndex === null || targetIndex === undefined) && dndTargets && dndTargets.length > 1}
        isLoading={isDndPending}
        color="positive"
      />,
      <Components.Button
        key="cancel"
        text="Отмена"
        icon="cancel-M"
        onPress={onCancelDnd}
        isLoading={isDndPending}
        color="negative"
      />
    ];
  }, [onConfirmDnd, targetIndex, isDndPending, onCancelDnd]);

  const dndDialogContent = useMemo(() => {
    if (!dragItem) {
      return null;
    }

    if (dndTargets && dndTargets.length > 1) {
      return (
        <div className="dialog-content">
          <div className="dnd-text">
            Выберите уровень вложенности для {<AisIcon className={"dnd-content-confirm-icon"} item={dragItem} />}
            {dragItem.title}
          </div>
          <div className="levels">
            {dndTargets.map((target, i) => {
              return (
                <div key={`${target.title}${i}`} className="button-holder" style={{ paddingLeft: `${2 * i}rem` }}>
                  <Components.Button
                    text={`${target.title || ""}${dragItem.captionTitle}`}
                    icon={dragItem.iconString || "list-bullet-M"}
                    onPress={() => setTargetIndex(i)} // eslint-disable-line
                    color={i === targetIndex ? "action" : undefined}
                    isDisabled={isDndPending}
                  />
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    return (
      <div className="dialog-content">
        Подтвердите перемещение для {<AisIcon className={"dnd-content-confirm-icon"} item={dragItem} />}{" "}
        {dragItem.title}
      </div>
    );
  }, [dndTargets, dragItem, isDndPending, targetIndex]);

  const addPictureViaDnD = useCallback(
    async(hoverItem) => {
      if (hoverItem.files && hoverItem.files.length > 0) {
        const file = hoverItem.files[0];
        const { type } = file;
        if (!(type && type.indexOf("image/") === 0)) {
          Notification.warning("В текст можно переносить только файлы в формате изображений.", { autoClose: 2500 });
          return;
        }
        const pictureData = item.plusMenuItems.find((i) => {
          return i.class === CLS_TEXT_PICTURE;
        });
        if (!pictureData) {
          return;
        }
        const picture = await item.createItem(pictureData, pictureData.kind);
        if (picture) {
          const src = await picture.uploadFile(file);
          await picture.saveImage(src);
        }
      }
    },
    [item]
  );

  const determineTargets = useCallback(
    (obj) => {
      const dragItem = dataStore.getItemById(obj.uid);
      const targets = [];
      dragItem &&
        item.plusMenuItems.forEach((plusMenuItem) => {
          if (plusMenuItem.class === dragItem.className) {
            targets.push(plusMenuItem);
          }
        });
      return targets;
    },
    [item, dataStore]
  );

  const onDrop = useCallback(
    async(hoverItem, monitor) => {
      setHoverItem(null);
      if (monitor.isOver({ shallow: true })) {
        if (monitor.getItemType() === DND_FILE_TYPE) {
          addPictureViaDnD(hoverItem);
        } else {
          const dragItem = dataStore.getItemById(hoverItem.uid);
          setDragItem(dragItem);
          const targets = [];
          item.plusMenuItems.forEach((plusMenuItem) => {
            if (plusMenuItem.class === dragItem.className) {
              targets.push(plusMenuItem);
            }
          });
          setDndTargets(targets);
          setTargetIndex(0);
          setIsDndDialogVisible(!!targets.length);
        }
      }
    },
    [item, dataStore]
  );

  const endHover = useCallback(() => {
    setHoverItem(null);
  }, [setHoverItem]);

  const onHover = useCallback(
    (item, monitor) => {
      if (item && !!item.version) {
        // у версий нельзя менять структуру
        setCanDropResult(false);
        setHoverItem(null);
        setDndTargets(null);
        setTargetIndex(null);
        return;
      }
      if (monitor.isOver({ shallow: true })) {
        setCanDropResult(monitor.canDrop());
        setHoverItem(item);
        setDndTargets(determineTargets(item));
        setTargetIndex(0);
      } else {
        setCanDropResult(monitor.canDrop());
        setHoverItem(null);
        setDndTargets(null);
        setTargetIndex(null);
      }
    },
    [setHoverItem, setDndTargets, setTargetIndex]
  );

  const checkRender = useCallback(
    (hoverItem) => {
      let target;
      if (dndTargets) {
        target = dndTargets[targetIndex];
      }
      if (!(target && target.ancorId)) {
        target = item;
      }
      const { parent, ancorUid } = dataStore.determineNewParentData(target);
      const ancor = ancorUid && dataStore.getItemById(ancorUid);
      if (hoverItem.uid && hoverItem.uid !== item.uid) {
        const hoverItemFromStore = dataStore.getItemById(hoverItem.uid);
        if (!hoverItemFromStore) return false; // refs T6146
        if (hoverItemFromStore.parent.uid === parent.uid) {
          // родитель не изменился
          if (dndTargets && dndTargets.length > 1) {
            return true;
          } else if (item.uid === parent.uid && hoverItemFromStore.position === 0) {
            // нависли над родителем первым child
            return false;
          } else if (item.uid !== parent.uid && hoverItemFromStore.position - 1 === item.position) {
            return false;
          } else if (ancor && hoverItemFromStore.position - 1 === ancor.position) {
            return false;
          } else {
            return true;
          }
        } else if (hoverItemFromStore.indexInFlatList - 1 === item.indexInFlatList) {
          if (dndTargets && dndTargets.length > 1) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    },
    [dndTargets, hoverItem, targetIndex]
  );

  const hoverItemRender = useMemo(() => {
    if (!hoverItem || !dndTargets || targetIndex === null) {
      return null;
    }
    let rItem;
    if (checkRender(hoverItem)) {
      rItem = dataStore.getItemById(hoverItem.uid);
    }

    if (hoverItem.files) {
      rItem = DataStore.textItemFabric({ id: "temp", class: CLS_TEXT_PICTURE }, 0, dataStore);
      // TODO: убрать явное назначение класса. Пока назанчение класса происходит только в методе init в
      // BaseTextObject
      rItem.init({ class: CLS_TEXT_PICTURE });
    }

    if (!rItem) {
      return null;
    }
    return (
      <FlatItem
        renderItem={renderItem}
        item={rItem}
        dataStore={dataStore}
        isPreview={true}
        canDropSelf={canDropResult}
      />
    );
  }, [item, hoverItem, renderItem, dataStore, canDropResult, dndTargets, targetIndex]);

  const canDrop = useCallback(
    (dndItem, monitor) => {
      if (dndItem && !!dndItem.version) {
        // у версий нельяз менять структуру
        return false;
      }
      const dndItemFromStore = dataStore.getItemById(dndItem.uid);
      const targets = determineTargets(item);
      const { parent } = dataStore.determineNewParentData(item);

      if (dndItemFromStore && dndItemFromStore.parent.uid === parent.uid) {
        // родитель не изменился
        if (item.uid !== parent.uid && dndItemFromStore.position - 1 === item.position) {
          // в ту же позицию в родителе
          return false;
        }
      }
      if (dndItemFromStore && dndItemFromStore.indexInFlatList - 1 === item.indexInFlatList) {
        // запрет в предыдущий эл-т
        if (dndItemFromStore.parent.uid !== item.parent.uid) {
          // если предыдущий - ребенок другого родителя
          if (targets.length > 1) {
            return true;
          } else {
            return false;
          }
        }
      }
      if (isPreview) {
        // no drop to DND Preview render
        return false;
      }
      if (item.uid === dndItem.uid || item.pathSet.has(dndItem.uid)) {
        // no drop to SELF or SELF CHILDREN
        return false;
      }
      if (!(item.plusMenuItems && item.plusMenuItems.length)) {
        // no drop if no create after is available
        return false;
      }
      let canDrop = false;
      if (monitor.getItemType() === DND_FILE_TYPE) {
        item.plusMenuItems.forEach((plusMenuItem) => {
          // Проверяем, есть ли разрешение на размещение здесь Изображения.
          if (plusMenuItem.class === CLS_TEXT_PICTURE) {
            // К сожалению, в dndItem.files и monitor.getItem().files при запросе переносимых файлов,
            // массив с переносимыми файлами всегда пустой. Получить информацию о переносимых файлах можно только
            // при событии Drop. Вот там и будем проверять, переносится ли картинка или другой тип файла.
            // А пока даем разрешение на Drop.
            canDrop = true;
          }
        });
      } else {
        item.plusMenuItems.forEach((plusMenuItem) => {
          if (
            plusMenuItem.class === dndItem.class &&
            plusMenuItem.availableKindsArray &&
            plusMenuItem.availableKindsArray.length
          ) {
            const realDnDItem = dataStore.getItemById(dndItem.uid);
            if (realDnDItem && !realDnDItem.hasKinds) {
              canDrop = true;
            } else if (realDnDItem && realDnDItem.kindsRepresentation) {
              plusMenuItem.availableKindsArray.forEach((availableKind) => {
                realDnDItem.kindsRepresentation.kindUids.forEach((kindUid) => {
                  if (kindUid === availableKind.kind) {
                    canDrop = true;
                  }
                });
              });
            }
          }
        });
      }

      return canDrop;
    },
    [item, isPreview]
  );

  if (!itemRender) {
    return null;
  }

  if (isPreview) {
    return (
      <div
        id={item.id}
        onMouseLeave={onMouseLeave}
        className={`virt-list-item preview ${canDropSelf ? "can-drop" : "can-not-drop"} ${item.diffClass}`}
      >
        <div ref={element} className="virt-list-item-content">
          {itemRender}
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <Dnd.Target
        drop={onDrop}
        canDrop={canDrop}
        hover={!isPreview && onHover}
        endHover={!isPreview && endHover}
        accept={[DND_EDITORITEM_TYPE, DND_FILE_TYPE]}
        className="text-flat-list-item-drop-target"
      >
        {!asParentDropZone && (
          <div
            id={item.id}
            style={{
              paddingLeft: `${item.listLevel * 2.25}rem`
            }}
            onMouseLeave={onMouseLeave}
            className={`virt-list-item ${item.diffClass}`}
          >
            <div ref={element} className="virt-list-item-content">
              {itemRender}
            </div>
          </div>
        )}
      </Dnd.Target>
      {hoverItemRender}
      {item && item.isExpanded && !isPreview && dataStore.isPlusVisible && (
        <PlusPreview
          style={{
            paddingLeft: `${item.listLevel * 2.25}rem`
          }}
        />
      )}
      <Modal.Window
        name="dnd-dialog"
        icon="cut-M"
        show={isDndDialogVisible}
        title={"Перемещение объекта"}
        buttons={dndDialogButtons}
        onKeyPressEnter={onConfirmDnd}
        onKeyPressEsc={onCancelDnd}
      >
        {dndDialogContent}
      </Modal.Window>
    </Fragment>
  );
});

export default FlatItem;
