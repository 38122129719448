import React from "react";

/**
 * Контекст для получения состояния Wizard и возможностью управлять шагами в нем
 * 
 * @type Context
 */
const context = React.createContext({
  /**
   * Ф-я для перехода на следующий шаг
   * @type {Function}
   */
  nextStep: () => {},

  /**
   * Ф-я для перехода на предыдущий шаг
   * 
   * @type {Function}
   */
  previousStep: () => {},

  /**
   * Ф-я для перехода на передаваймый шаг
   * 
   * @type {Function}
   * @param {Number} stepIndex индекс шага. Начинается с 0
   */
  // eslint-disable-next-line no-unused-vars
  goToStep: (stepIndex) => {},
  
  /**
   * Прикрепить callback ф-ю для вызова при переходе на следующий шаг  - `nextStep()`
   * 
   * @type {Function}
   * @param {Function} handler Мб синхронный и асинхронный вызов
   */
  // eslint-disable-next-line no-unused-vars
  handleStep: (handler) => {},

  /**
   * Ф-я для окончания работы Wizard
   * 
   * @type {Function}
   * @param {Object} data набор данных
   */
  // eslint-disable-next-line no-unused-vars
  done: (data) => {},

  /**
   * Текущее состояние обработчика
   *
   * Отражать состояние обработчика: 
   *  `true` - обработчик находится в состоянии ожидания, 
   *  `false` - если обработчик выполнен или отклонен.
   * 
   * @type {Boolean}
   */
  isLoading: false,

  /** 
   * Индекс текущего шага
   * 
   * @type {Number}
   */
  activeStep: 0,

  /** 
   * Общее кол-во шагов в Wizard
   * 
   * @type {Number}
   */
  stepCount: 0,

  /** 
   * Признак, что текущий шаг является первым (нет предыдущего шага)
   * 
   * @type {Boolean}
   */
  isFirstStep: true,

  /** 
   * Признак, что текущий шаг является последний (нет следующего шага)
   * 
   * @type {Boolean}
   */
  isLastStep: false,

  /** 
   * Признак, что текущий шаг валидный, те заполенны все поля
   * 
   * @type {Boolean}
   */
  isValid: false,

  /**
   * Ф-я для задания св-ва isValid, те все данные на шаге верно заполнены
   * 
   * @type {Function}
   * @param {Boolean} value значение валидность true | false
   */
  // eslint-disable-next-line no-unused-vars
  setIsValid: ((value) => {}),

  /** 
   * Данные, сформированные на шаге.
   * Здесь можно будет хранить данные, сформирвоаннаые на каждом шаге, например в массиве.
   * Или только определенную сущность. Все будет зависеть от задачи и решения разработчика 
   * 
   * @type {Any}
   */
  stepData: undefined,

  /**
   * Ф-я для задания св-ва stepData
   * 
   * @type {Function}
   * @param {Any} value значение 
   */
  // eslint-disable-next-line no-unused-vars
  setStepData: ((value) => {})
});

export default context;
