import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import {  Field } from "@ais3p/ui-framework";
import { CustomFieldModel } from "../../models";


/**
 * Компонент для формирвоания номера КП
 * 
 * @param {Object} props набор параметров
 * @param {CustomFieldModel} props.сustomFieldModel модель пользовательского поля
 * @param {Any}  props.value значение поля
 * @param {Boolean} props.readOnly флаг указывающий, что пользователей в списске можно только проссматривать
 * @param {Function} props.onChange callback функция при добавлении/удалении пользователя
 */
const CoordLetterNumberField = observer(({ value, template, customField, readOnly, onChange }) => {
  const isValid = useMemo(() => {
    return  (template && !!template.numPrefix) && customField.validateValue(value).isValid;
  }, [customField, value, template && template.numPrefix]);

  // const hint = useMemo(() => {
  //   return  customField.validateValue(value).hint;
  // }, [customField, value]);

  return (
    <div className="ais-field-container coord-letter-number">
      <div className="ais-field-label string">{customField.name}</div>
      <div className="coord-letter-number-container">
        <div className="coord-letter-number-prefix">
          {`${template.numPrefix}${template.nextNumber}-`}
        </div>
        <Field.DateTime 
          name={customField.id}
          label=""
          value={value}
          isDisabled={readOnly}
          isValid={isValid}
          isRequired={true}
          showTimeInput={false}
          onChange={onChange}
        />
      </div>
    </div>
  );
});

CoordLetterNumberField.propTypes = {
  value:       PropTypes.array, 
  customField: PropTypes.instanceOf(CustomFieldModel), 
  readOnly:    PropTypes.bool, 
  onChange:    PropTypes.func
};

export default CoordLetterNumberField;
