import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import {  Field } from "@ais3p/ui-framework";
import { CustomFieldModel } from "../../models";


/**
 * Компонент для отображения поля с версиями
 * 
 * @param {Object} props набор параметров
 * @param {CustomFieldModel} props.сustomFieldModel модель пользвоательского поля
 * @param {Any}  props.value значение поля
 * @param {Boolean} props.readOnly флаг указывающий, что пользователей в списске можно только проссматривать
 * @param {Function} props.onChange callback функция при добавлении/удалении пользователя
 */
const VersionField = observer(({ value, customField, readOnly, onChange }) => {
  const isValid = useMemo(() => {
    return  customField.validateValue(value).isValid;
  }, [customField, value]);

  const hint = useMemo(() => {
    return  customField.validateValue(value).hint;
  }, [customField, value]);

  const opts = useMemo(() => {
    return Array.isArray(customField.possibleValues) ? customField.possibleValues.map((item) => {
      return {
        value: item.key,
        label: item.label
      };
    }) : [];
  }, [customField.possibleValues]);

  if (customField.multiple) {
    return (
      <Field.MultiSelect
        icon="version-M"
        label={customField.name}
        name={customField.id}
        isDisabled={!customField.visible || readOnly}
        isValid={isValid === false ? false : undefined}
        hint={hint}
        value={value}
        onChange={onChange}
        isClearable={false}
        placeholder="Выберите версию"
        multiselect={true}
        options={opts}
      />
    );
  } else {
    return (
      <Field.SingleSelect
        icon="version-M"
        label={customField.name}
        name={customField.id}
        isDisabled={!customField.visible || readOnly}
        isValid={isValid === false ? false : undefined}
        hint={hint}
        value={value}
        onChange={onChange}
        placeholder="Выберите версию"
        options={opts}
      />
    );
  };
});

VersionField.propTypes = {
  value:       PropTypes.array, 
  customField: PropTypes.instanceOf(CustomFieldModel), 
  readOnly:    PropTypes.bool, 
  onChange:    PropTypes.func
};

export default VersionField;
