import Server from "~/core/api/server";

/**
 * Api запросов для работы с сервисом журналированием действий пользователя
 *
 * @class JournalApi
 */
export default class JournalApi extends Server {
  methods = {
    loadRecords: {
      url: (params) => {
        const str = Object.keys(params)
          .filter((key) => {
            return !!params[key];
          })
          .map((key) => {
            if (Array.isArray(params[key])) {
              return `${key}[]=${encodeURIComponent(JSON.stringify(params[key]))}`;
            } else {
              return `${key}=${params[key]}`;
            }
          })
          .join("&");
        return this.getServiceUrl("journal", this.template`api/v1/records?${0}`, [str]);
      },
      method:      "GET",
      textProcess: "Загрузка списка записей журнала действий пользователя ...",
      textSuccess: null,
      textError:   this.template`Во время загрузки списка задач произошла ошибка: ${0}`
    },
    loadRecord: {
      url: (params) => {
        return this.getServiceUrl("journal", this.template`api/v1/records/${0}`, params);
      },
      method:      "GET",
      textProcess: "Загрузка записи журнала действий пользователя...",
      textSuccess: null,
      textError:   this.template`Во время загрузки записи журнала действий пользователя произошла ошибка: ${0}`
    },
    userList: {
      url:         this.getServiceUrl("users", "api/v2/users"),
      method:      "GET",
      textProcess: "Загрузка списка пользователей ...",
      textSuccess: null,
      textError:   this.template`Во время загрузки списка пользователей произошла ошибка: ${0}`
    },
    loadHistory: {
      url: (params) => {
        const str = Object.keys(params)
          .filter((key) => {
            return !!params[key];
          })
          .map((key) => {
            if (Array.isArray(params[key])) {
              return `${key}[]=${encodeURIComponent(JSON.stringify(params[key]))}`;
            } else {
              return `${key}=${params[key]}`;
            }
          })
          .join("&");
        // return this.getServiceUrl("journal", this.template`api/v1/history?${0}`, [str]);
        return this.getServiceUrl("journal", this.template`api/v2/history?${0}`, [str]);
      },
      method:      "GET",
      textProcess: "Загрузка списка записей журнала действий пользователя ...",
      textSuccess: null,
      textError:   this.template`Во время загрузки списка задач произошла ошибка: ${0}`
    }
  };

  /**
   * Загрузить список записей журнала действий пользователя
   *
   * @params {Object} params опции, передаваемые в запросе через query-параметры:
   * @params {String} params.subject `uuid` - идентификатор пользователя
   * @params {String} params.objects `uuid` - идентификатор объекта, над которым происходит действие
   * @params {String} params.action тип действия (create, read, update, delete)
   * @params {String} params.klass класс объекта в системе АИС ППП
   * @params {Number} params.version версия объекта в системе АИС ППП, если он поддерживает версионность,
   *                                 соответственно параметр опциональный
   * @params {DateTime} params.from `timestamp` в формате rfc3339 (iso 8601) (с какого момента времени)
   * @params {DateTime} params.to `timestamp` в формате rfc3339 (iso 8601) (по какой момент времени)
   * @params {Number} params.page страница
   * @params {Number} params.perPage количество записей на странице

   * @return {Promise}
   */
  async loadRecords(params = {}) {
    const reqData = {
      params
    };
    return await this.request("loadRecords", reqData);
  }

  /**
   * Загрузить запись журнала
   *
   * @params {String} uid записи журнала
   *
   * @return {Promise}
   */
  async loadRecord(uid) {
    const reqData = {
      params: [uid]
    };
    return await this.request("loadRecord", reqData);
  }

  /**
   * Загрузить список пользователей
   *
   * @return {Promise}
   */
  async loadUsers() {
    return await this.request("userList");
  }

  /**
   * Загрузить список истории изменений
   *
   * @params {Object} params опции, передаваемые в запросе через query-параметры:
   * @params {String} params.subject `uuid` - идентификатор пользователя
   * @params {String} params.objects `uuid` - идентификатор объекта, над которым происходит действие
   * @params {String} params.action тип действия (create, read, update, delete)
   * @params {String} params.klass класс объекта в системе АИС ППП
   * @params {Number} params.version версия объекта в системе АИС ППП, если он поддерживает версионность,
   *                                 соответственно параметр опциональный
   * @params {DateTime} params.from `timestamp` в формате rfc3339 (iso 8601) (с какого момента времени)
   * @params {DateTime} params.to `timestamp` в формате rfc3339 (iso 8601) (по какой момент времени)
   * @params {Number} params.page страница
   * @params {Number} params.perPage количество записей на странице

   * @return {Promise}
   */
  async loadHistory(params = {}) {
    const reqData = {
      params
    };
    return await this.request("loadHistory", reqData);
  }
}
