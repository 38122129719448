import React, { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react";

// import User from "./User";
import HeaderButton from "./HeaderButton";
import AppLogo from "./AppLogo";
import useStores from "../utils/useStores";
import AccountButton from "./AccountButton";

/**
 * Боковая панель клиента АИС ППП
 */
const AppHeader = observer((props) => {
  const {
    buttons,
    modeButtons,
    setStore,
    getPresset,
    menuCollect,
    setIsDragging,
    onShowAbout
  } = props;

  const { layoutStore } = useStores();

  const { mode, setMode } = useState();
  const onSetMode = useCallback(
    (mode) => {
      const newMode = props.setMode(mode);
      setMode(newMode);
    },
    [props.setMode]
  );

  const onOpen = useCallback((itemData) => {
    layoutStore.open(itemData);
  }, [layoutStore]);
  
  const headerButtons = useMemo(() => {
    return buttons.map((button) => {
      return (
        <HeaderButton
          setStore={setStore}
          getPresset={getPresset}
          menuCollect={menuCollect}
          key={button.id}
          {...button}
          onClick={onOpen}
          setIsDragging={setIsDragging}
          canDrag={!button.modal}
        />
      );
    });
  }, [buttons, setStore, getPresset, menuCollect, setIsDragging, open]);

  const modeHeaderButtons = useMemo(() => {
    return modeButtons.map((button) => {
      return (
        <HeaderButton
          setStore={setStore}
          getPresset={getPresset}
          menuCollect={menuCollect}
          key={button.id}
          {...button}
          onClick={button.onClick || (button.mode ? onSetMode : open)}
          currentMode={mode}
          setIsDragging={setIsDragging}
          canDrag={!button.modal}
        />
      );
    });
  }, [
    modeButtons,
    setStore,
    getPresset,
    menuCollect,
    setIsDragging,
    open,
    mode
  ]);

  return (
    <div className="app-header">
      <div className="left-side">
        <AppLogo 
          onShowAbout={onShowAbout}
        />
        {headerButtons}
      </div>
      <div className="right-side">
        {modeHeaderButtons}
        <AccountButton />
      </div>
    </div>
  );
});

export default AppHeader;
