import {
  computed
} from "mobx";

import IssueStore from "../stores/IssueStore";

/**
 * Базовый  Redmine объект
 *
 * @class BaseRedmineModel
 */
class BaseRedmineModel {
  // @observable
  id = undefined;

  issueStore = undefined;

  /**
   * RootStore - иногда модели нужно будет принудительно задать rootStore, чтобы модель могла
   * получить данные из других хранилищ, а хранилища IssueStore нет.
   *
   * @type RootStore
   */
  rootStore = undefined;

  /**
   * Конструктор
   *
   * @param {Object} params параметры объекта
   * @param {String} params.id id сущности в Redmine
   * @param {IssueStore} issueStore хранилище задач
   */
  constructor({ id }, issueStore) {
    // if (!id) {
    //   throw new Error("Не указан id сущности!");
    // } else {
    //   this.id = id;
    // }
    this.id = id;
    if (issueStore instanceof IssueStore) {
      this.issueStore = issueStore;
      this.rootStore = issueStore.rootStore;
    }

    // Если делать проверку через instanceof, то просиходит зацикливание с import объектов
    // if (issueStore instanceof RootStore) {
    //   this.rootStore = issueStore;
    // }

    // поэтому проверяю через наличие uiStore, тк он есть только в rootStore
    if (issueStore && issueStore.uiStore) {
      this.rootStore = issueStore;
    }
  }


  /**
   * Вид объекта
   */
  get kindModel() {
    return "baseRedmine";
  }

  /**
   * Название объекта
   */
  @computed
  get title() {
    return this.id;
  }

  /**
   * Флаг, указывающий если связь сущности в Redmine сущностью в АИС
   *
   * @return {Boolean}
   */
  @computed
  get isLinked() {
    return !!this.uid;
  }

  /**
   * Флаг, указывающий новая ли сущность
   *
   * @return {Boolean}
   */
  @computed
  get isNew() {
    return !this.id;
  }

  /**
   * Уничтожить объект.
   */
  destroy() {
  }
}

export default BaseRedmineModel;
