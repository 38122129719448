import React from "react";
import { observer } from "mobx-react";
import { Components, Tabs } from "@ais3p/ui-framework";
import useStores from "~/core/utils/useStores";
import ChangePassword from "../components/ChangePassword";
import UserForm from "../components/UserForm";

import "./css/index.scss";

/**
 * Инструмент для Редактирования профиля текущего пользователя
 * @params {Object} props набор параметров
 * @params {String} props.schemaId id схемы, согласно которой нужно будет делать трассировку или анализ связей
 * @params {String} props.traceType что делать - трассировку "trace" | анализ связей "gap"
 * @params {Object} props.trackedItem информация о ноде в Библиотеке, для которой была вызвана трассировка или 
 * анализ связей
 */
const ProfileTool = observer(() => {
  const { accountStore } = useStores();

  return (
    <div className="profile-tool">
      {
        accountStore.isPending &&
        <div className="profile-preloader">
          <Components.Preloader size={3} className="preloader-icon" />
        </div>
      }
      <div className="profile-tool-body" >
        <Tabs
          defaultIndex={0}
          items={[
            {
              id:        "profile",
              label:     "Профиль",
              icon:      "user-edit-M",
              component: <UserForm />
            },
            {
              id:        "password",
              label:     "Изменить пароль",
              icon:      "fixed-M",
              component: <ChangePassword />
            }
          ]}
        />
      </div>
    </div>
  );
});


export default ProfileTool;
