import { action, computed, observable } from "mobx";
import BaseBoxClass from "./BaseBoxClass";

class TableRow extends BaseBoxClass {
  // @observable
  // isHeader = false;
  @observable
  creationPromice = null;

  @action
  init(data, parent) {
    super.init(data, parent);
    // this.isHeader = !!data.isHeader;
    if (data.isNew) {
      this.creationPromice = this.persistCreate();
    }
  }

  @action
  setUpCell(uid) {
    if (this.isFirst) {
      this.parent.setPrevChild(this.uid);
    } else {
      const index = this.getItemIndexById(uid);
      const prevId = this.parent.getPrevId(this.uid);
      if (prevId) {
        const prevItem = this.store.getItemById(prevId);
        if (prevItem.className === "text.container.TableRow") {
          prevItem && prevItem.setEditingByIndex(index);
        } else {
          prevItem.setEditing();
        }
      }
    }
  }

  @action
  setDownCell(uid) {
    if (this.isLast) {
      this.parent.setNextChild(this.uid);
    } else {
      const index = this.getItemIndexById(uid);
      const nextId = this.parent.getNextId(this.uid);
      if (nextId) {
        const nextItem = this.getItemById(nextId);
        nextItem && nextItem.setEditingByIndex(index);
      }
    }
  }

  @action
  setEditingByIndex(index) {
    let item = null;
    if (this.idsArray.length < index) {
      item = this.getItemById(this.idsArray[this.idsArray.length - 1]);
    } else {
      item = this.getItemById(this.idsArray[index]);
    }
    item && item.setEditing();
  }

  @action
  setHoverRect(from, to) {
    this.parent.setHoverRect(from, to);
  }

  @action
  async insertColumn(position = 0) {
    return await this.parent.insertColumn(position);
  }

  @action
  async insertRowTop(position) {
    return await this.parent.insertRow(position);
  }

  @action
  async insertRowBottom(position) {
    return await this.parent.insertRow(position);
  }

  @action
  async mergeCells() {
    return await this.parent.mergeCells();
  }

  @action
  async splitCell(uid, direction) {
    return await this.parent.splitCell(uid, direction);
  }

  @action
  deleteRow(rowNumber) {
    this.parent.deleteRow(rowNumber);
  }

  @action
  deleteColumn(columnNumber) {
    this.parent.deleteColumn(columnNumber);
  }

  @computed
  get isHeader() {
    return this.indexNumber === 1;
  }

  @computed
  get isRow() {
    return true;
  }

  @computed
  get countedItems() {
    const items = [];
    this.countedCellIds.forEach((uid) => {
      items.push(this.store.getItemById(uid));
    });
    return items;
  }

  @computed
  get countedCellIds() {
    const cellIds = [...this.idsArray];
    if (this.prevSibling && this.prevSibling.isRow) {
      this.prevSibling.countedItems.forEach((cell, i) => {
        if (
          cell.rowEnd >= this.position + 1 &&
          cell.rowNumber <= this.position
        ) {
          cellIds.splice(i, 0, cell.uid);
        }
      });
    }
    return cellIds;
  }

  @computed
  get slug() {
    return "table-rows";
  }

  @computed
  get table() {
    return this.parent;
  }

  @computed
  get tableUid() {
    return this.parent.uid;
  }

  @computed
  get hoverBounds() {
    return this.parent.countedHoverBounds;
  }

  @computed
  get isHoverValid() {
    return this.parent.isHoverValid;
  }

  @computed
  get category() {
    return "table-rows";
  }
  @computed
  get flatItemsArray() {
    return [this];
  }


  @computed
  get columnsCount() {
    let count = 0;
    this.countedItems.forEach((cell) => {
      count += cell.cs;
    });
    return count;
  }

  @computed
  get tableColumnsCount() {
    if (!!this.store.parentStore) {
      const realItem = this.store.parentStore.getItemById(this.parent.uid);
      if (realItem) {
        return realItem.columnsCount || 0;
      }
    }
    return (this.parent && this.parent.columnsCount) || 0;
  }

  @computed
  get missingItemsCount() {
    return Math.max(this.tableColumnsCount - this.columnsCount, 0);
  }

  @computed
  get isFirstRow() {
    return this.isFirst;
  }

  @computed
  get defaultInnerItemsArray() {
    return this.parent.defaultInnerItemsArray;
  }

  @computed
  get removedFirstChildListItemsArray() {
    if (!this.diffCompatitor || this.store.isPending || this.store.isDiffPending) {
      return [];
    }
    if (
      this.diffCompatitor.items && 
      this.diffCompatitor.items.length > 0 && 
      this.diffCompatitor.items[0].diffClass === "removed"
    ) {
      return [this.diffCompatitor.items[0], ...this.diffCompatitor.items[0].removedDescendantListItemsArray];
    }
    return [];
  }

  @computed
  get removedDescendantListItemsArray() {
    if (!this.diffCompatitor || this.store.isPending || this.store.isDiffPending) {
      return [];
    }
    if (this.nextSibling && this.nextSibling.diffClass === "removed") {
      return [this.nextSibling, ...this.nextSibling.removedDescendantListItemsArray];
    }
    if (this.diffCompatitor.nextSibling && this.diffCompatitor.nextSibling.diffClass === "removed") {
      return [this.diffCompatitor.nextSibling, ...this.diffCompatitor.nextSibling.removedDescendantListItemsArray];
    }
    return [];
  }

  @computed
  get itemsForRender() {
    let data = [...this.removedFirstChildListItemsArray];
    this.idsArray.forEach((id) => {
      const item = this.getItemById(id);
      data = data.concat([item, ...item.removedDescendantListItemsArray]);
    });
    return data;
  }

  @computed
  get output() {
    return {
      class:       this.className,
      uid:         this.uid,
      cells:       this.idsArray,
      "@position": this.position
    };
  }
}

export default TableRow;
