import BaseRedmineModel from "./baseRedmineModel";

import { observable, computed } from "mobx";

/**
 * Модель пользователя из Redmine
 *
 * @class RedmineUserModel
 */
class RedmineUserModel extends BaseRedmineModel {
  /**
   * uid пользователя в АИС
   *
   * @type String
   */
  @observable
  uid = undefined;

  /**
   * Имя пользователя
   *
   * @type String
   */
  @observable
  name = undefined;

  /**
   * Email пользователя
   * (приходит на прямой запрос списка пользователей в Redmine)
   * @type String
   */
  @observable
  email = undefined;

  /**
   * Имя пользователя
   * (приходит на прямой запрос списка пользователей в Redmine)
   * @type String
   */
  @observable
  firstName = undefined;

  /**
   * Фамилия пользователя
   * (приходит на прямой запрос списка пользователей в Redmine)
   * @type String
   */
  @observable
  lastName = undefined;

  /**
   * Логин пользователя
   * (приходит на прямой запрос списка пользователей в Redmine)
   * @type String
   */
  @observable
  login = undefined;

  /**
   * Cоздание модели
   *
   * @param {Object} params параметры модели
   * @param {String} params.id id пользователя в Redmine
   * @param {String} params.uid uid пользователя в АИС
   * @param {String} params.name имя пользователя
   * @param {String} params.login логин пользователя (приходит на прямой запрос списка пользователей в Redmine)
   * @param {String} params.email email пользователя (приходит на прямой запрос списка пользователей в Redmine)
   * @param {String} params.firstname имя пользователя (приходит на прямой запрос списка пользователей в Redmine)
   * @param {String} params.lastname фамилия пользователя (приходит на прямой запрос списка пользователей в Redmine)
   * @param {IssueStore} issueStore хранилище задач
   *
   * @retrun {UserModel}
   */
  static create({
    id,
    uid,
    name,
    login,
    email,
    firstname,
    lastname
  }, issueStore) {
    return new RedmineUserModel({
      id,
      uid,
      name,
      login,
      email,
      firstName: firstname,
      lastName:  lastname
    }, issueStore);
  }

  constructor(params, issueStore) {
    super(params, issueStore);

    this.uid = params.uid;
    this.name = params.name;
    this.login = params.login;
    this.email = params.email;
    this.firstName =  params.firstName;
    this.lastName = params.lastName;
  }

  /**
   * Вид модели
   * @return {String}
   */
  get kindModel() {
    return "redmineUserModel";
  }

  /**
   * Строковое представление пользователя
   *
   * @return {String}
   */
  @computed
  get title() {
    return this.name || `${this.firstName} ${this.lastName}`;
  }

  @computed
  get shortName() {
    if (this.firstName && this.lastName) {
      return `${this.lastName}  ${this.firstName[0]}`;
    } else {
      return this.name;
    }
  }

  /**
   * Объект АИС пользователя
   *
   * @return {UserModel}
   */
  @computed
  get aisUser() {
    if (!this.issueStore) {
      console.error("У модели RedmineUserModel не задан issueStore!");
      return undefined;
    }

    return this.issueStore.rootStore.userStore.get(this.uid);
  }

  /**
   * @computed
   * Значение для конфигурационного файла.
   *
   * @return {Object} данные
   */
  get config() {
    return {
      id:        this.id,
      uid:       this.uid,
      name:      this.name,
      login:     this.login,
      email:     this.email,
      firstname: this.firstName,
      lastname:  this.lastName
    };
  }
}

export default RedmineUserModel;
