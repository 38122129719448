import React from "react";
import WizardContext from "../context";

/**
 * Hook для получения состояния Wizard и управление шагами в нем
 * 
 * @returns Context
 */
const useWizard = () => {
  const context = React.useContext(WizardContext);

  if (!context) {
    throw Error("Оберните компонент шага <Wizard>");
  } else {
    return context;
  }
};

export default useWizard;
