/**
 * Функция для склонения числительных
 * 
 * @param {Number} value значение числа, которое нужно просклонять
 * @param {Array<String>} words набор строк из трех значений для склонения:
 *  1.  Каким будет слово для одной единицы?
 *  2. Каким будет слово для двух единиц?
 *  3. Каким будет слово для пяти единиц?
 * 
 * @return {String}
 */
const declOfNum = (value, words = []) => {  
  const val = Math.abs(value) % 100; 
  const num = val % 10;
  if (val > 10 && val < 20) return words[2]; 
  if (num > 1 && num < 5) return words[1];
  if (num === 1) return words[0]; 
  return words[2];
};

export default declOfNum;
