import React from "react";
import classNames from "classnames";
import AisIcon from "~/core/components/AisIcon";

/**
 * Компонент для отображения/редактирования строкового значения в ячейке табличного представления результата CheckList
 * 
 * @param {String} name имя переменной строкового значения
 * @param {String} value значение переменной строкового значения
 * @param {String} className пользовательский className
 * @param {Func} onChange callback ф-я на изменение значения
 */
const GapContent = ({ className }) => {
  return (
    <div
      className={classNames("gap", className)}
      data-tooltip={"Отсутствует объект"}
      data-tooltip-at="bottom"
    >
      <AisIcon className="icon negative" icon={"link-delete-M"} />
    </div>
  );
};

export default GapContent;
