import BaseRedmineModel from "./baseRedmineModel";
import CustomFieldModel from "./customFieldModel";

import { observable, computed, action } from "mobx";

/**
 * Модель трекера. Должна быть связь с Видом РМ в АИС по его uid
 *
 * @class TrackerModel
 */
class TrackerModel extends BaseRedmineModel {
  /**
   * uid вида в АИС
   *
   * @type String
   */
  @observable
  uid = undefined;

  /**
   * Название трекера
   *
   * @type String
   */
  name = undefined;

  /**
   * Пользовательские поля
   *
   * @type String
   */
  customFieldsMap = new Map();

  /**
   * Cоздание модели
   *
   * @param {Object} params параметры модели
   * @param {String} params.id id трекера в Redmine
   * @param {String} params.uid uid вида в АИС
   * @param {String} params.name название трекера
   * @param {Array<Object>} params.customFields список пользовательских полей
   * @param {IssueStore} issueStore хранилище задач
   *
   * @retrun {TrackerModel}
   */
  static create({
    id,
    uid,
    name,
    customFields = []
  }, issueStore) {
    return new TrackerModel({
      id,
      uid,
      name,
      customFields
    }, issueStore);
  }

  constructor(params, issueStore) {
    super(params, issueStore);

    this.uid = params.uid;
    this.name = params.name;
    
    if (Array.isArray(params.customFields)) {
      params.customFields.forEach((custom) => {
        const field = CustomFieldModel.create(custom, issueStore);
        this.customFieldsMap.set(field.id, field);
      }, []);
    }
  }

  /**
   * Вид модели
   * @return {String}
   */
  get kindModel() {
    return "trackerModel";
  }

  /**
   * Обновить св-ва модели
   * @param {Object} params параметры модели
   * @param {String} params.name название трекера
   */
  @action
  update(data) {
    this.name = data.name || this.name;
  }

  /**
   * Строковое представление трекера
   *
   * @return {String}
   */
  @computed
  get title() {
    return this.name;
  }

  /**
   * Флаг, указывающий если связь трекера с видом в АИС
   *
   * @return {Boolean}
   */
  @computed
  get isLinked() {
    return !!this.uid;
  }

  /**
   * Список пользовательских полей
   *
   * @return {Array<CustomField>}
   */
  @computed
  get customFields() {
    return Array.from(this.customFieldsMap.values());
  }

  /**
   * Получить пользовательское поле по его id
   *
   * @params {Number} id поля
   * 
   * @return {CustomField}
   */
  getCustomField(id) {
    return this.customFieldsMap.get(id);
  }

  /**
   * @computed
   * Значение для конфигурационного файла.
   *
   * @return {Object} данные
   */
  get config() {
    return {
      id:           this.id,
      uid:          this.uid,
      name:         this.name,
      customFields: this.customFields.map((field) => {
        return field.config;
      })
    };
  }
}

export default TrackerModel;
