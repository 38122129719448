import React, { useCallback, useState, useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { observer } from "mobx-react";
import { Components, ContextMenu } from "@ais3p/ui-framework";
import classNames from "classnames";

import { colorShade } from "~/core/utils";
import useStores from "~/core/utils/useStores";
import {
  SHADE_BACKGROUND_COLOR,
  SHADE_BORDER_COLOR
} from "../../constants/colorShade";
import IssueIcon from "./IssueIcon";

import {
  ISSUE_VALUE_ID,
  ISSUE_VALUE_UID,
  ISSUE_VALUE_AUTHOR,
  ISSUE_VALUE_ASSIGNED_TO,
  ISSUE_VALUE_CREATED_ON,
  ISSUE_VALUE_DESCRIPTION,
  ISSUE_VALUE_DONE_RATIO,
  ISSUE_VALUE_DUE_DATE,
  ISSUE_VALUE_ESTIMATED_HOURS,
  ISSUE_VALUE_PRIORITY_ID,
  ISSUE_VALUE_PROJECT,
  ISSUE_VALUE_START_DATE,
  ISSUE_VALUE_STATUS_ID,
  ISSUE_VALUE_SUBJECT,
  ISSUE_VALUE_TRACKER,
  ISSUE_VALUE_UPDATED_ON,
  ISSUE_VALUE_WATCHERS,
  ISSUE_VALUE_SELECTABLE
} from "../../constants/values";

import UserItem from "../user/UserItem";
import IssueStore from "../../stores/IssueStore";
import { IssueModel } from "../../models";
import { CTX_MENU_AIS_OBJECT_LINK } from "~/core/constants/ContextMenu";
import TableSelectionCell from "./TableSelectionCell";

/**
 * Компонент для отображения значений в табличном предтставлении списка задач
 * 
 * @param {Object} props набор параметров
 * @param {IssueStore} params.store хранилище для работы с задачами
 * @param {String} params.columnId идентификатор колонки - ключ значения задачи
 * @param {IssueModel} params.issue объект задачи
 * @param {LayoutItem} params.layoutItem сущность в Layout
 */
const TableValue = observer(({ store, columnId, issue, layoutItem }) => {
  const { uiStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);

  const value = useMemo(() => {
    return issue[columnId];
  }, [issue, columnId]);

  const onClick = useCallback(async() => {
    if (isLoading) {
      // уже идет загрука задачи
      return;
    }
    setIsLoading(true);
    try {
      await store.openIssue(issue.uid);
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, issue]);

  switch (columnId) {
    case ISSUE_VALUE_SELECTABLE:
      return (
        <TableSelectionCell
          issue={issue}
          store={store}
        />
      );
    case ISSUE_VALUE_ID:
      return (
        <ContextMenu.Trigger
          menuId={CTX_MENU_AIS_OBJECT_LINK}
          context={{ object: issue }}
        >
          <div className="issues-table-cell-id" onClick={onClick}>
            {isLoading && (
              <div className="issues-table-cell-preloader">
                <Components.Preloader size={1.5} />
              </div>
            )}
            <div className="issues-table-cell-index">
              {issue.titlePrefix}
            </div>
          </div>
        </ContextMenu.Trigger>
      );
    case ISSUE_VALUE_SUBJECT:
      return (
        <ContextMenu.Trigger
          menuId={CTX_MENU_AIS_OBJECT_LINK}
          context={{ object: issue }}
        >
          <span
            className="issues-table-cell-text issues-table-cell-title"
            onClick={onClick}
          >
            {value}
          </span>
        </ContextMenu.Trigger>
      );
    case ISSUE_VALUE_UID:
    case ISSUE_VALUE_DESCRIPTION:
      return <span className="issues-table-cell-text">{value}</span>;
    case ISSUE_VALUE_DONE_RATIO:
      return <span className="issues-table-cell-text">{`${value}%`}</span>;
    case ISSUE_VALUE_ESTIMATED_HOURS:
      return (
        <span className="issues-table-cell-text">{value && `${value} ч.`}</span>
      );
    case ISSUE_VALUE_AUTHOR:
    case ISSUE_VALUE_ASSIGNED_TO:
      return value && value.id ? (
        <UserItem userId={value && value.id} store={store} />
      ) : null;

    case ISSUE_VALUE_CREATED_ON:
    case ISSUE_VALUE_START_DATE:
    case ISSUE_VALUE_UPDATED_ON:
      return (
        <span className="issues-table-cell-date">
          {value && moment(value).format("DD.MM.YYYY")}
        </span>
      );
    case ISSUE_VALUE_DUE_DATE: {
      return (
        <div
          className={classNames("issues-table-cell-date", {
            overdue: value && value < new Date()
          })}
        >
          {value && moment(value).format("DD.MM.YYYY")}
        </div>
      );
    }

    case ISSUE_VALUE_STATUS_ID: {
      const status = store.getStatus(value);
      return (
        <span className="issues-table-cell-text">
          {(status && status.title) || value}
        </span>
      );
    }
    case ISSUE_VALUE_PRIORITY_ID: {
      const priority = store.getPriority(value);
      if (!priority) {
        return null;
      }
      let priorityStyle;
      if (priority.color) {
        const colorName = uiStore.colors.getBaseColorName(
          uiStore.colors.getColorNameByHex(priority.color)
        );
        const bgColor =
          (colorName && uiStore.colors.getColorByName(`${colorName}1`)) ||
          colorShade(priority.color, SHADE_BACKGROUND_COLOR);
        const borderColor =
          (colorName && uiStore.colors.getColorByName(`${colorName}3`)) ||
          colorShade(priority.color, SHADE_BORDER_COLOR);
        const txtColor =
          (colorName && uiStore.colors.getColorByName(`d${colorName}3`)) ||
          priority.color;

        priorityStyle = {
          backgroundColor: bgColor,
          borderColor,
          color:           txtColor
        };
      }

      return (
        <div className="issues-table-cell-tag" style={priorityStyle}>
          {(value && priority.title) || value}
        </div>
      );
    }

    case ISSUE_VALUE_TRACKER:
      return (
        <ContextMenu.Trigger
          menuId={CTX_MENU_AIS_OBJECT_LINK}
          context={{ object: issue }}
        >
          <IssueIcon store={store} issue={issue} layoutItem={layoutItem} />
        </ContextMenu.Trigger>
      );
    case ISSUE_VALUE_PROJECT:
      return (
        <span className="issues-table-cell-text">{value && value.title}</span>
      );

    case ISSUE_VALUE_WATCHERS:
      return (
        value &&
        value.map((user) => {
          return <UserItem key={user.id} userId={value.id} store={store} />;
        })
      );
    default:
      return <span className="issues-table-cell-text">{value}</span>;
  }
});

TableValue.propTypes = {
  columnId:   PropTypes.string.isRequired, 
  store:      PropTypes.instanceOf(IssueStore).isRequired, 
  issue:      PropTypes.instanceOf(IssueModel).isRequired, 
  layoutItem: PropTypes.object.isRequired
};

export default TableValue;
