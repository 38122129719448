/**
 * Набор констант для сохранения конфигурации
 */
const MODULE_NAME_CFG = "text";
const TREE_CFG = "tree";

export {
  MODULE_NAME_CFG,
  TREE_CFG
};
