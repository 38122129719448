import React, { useState, useRef, useCallback, useMemo, Children, isValidElement, cloneElement } from "react";
import PropTypes from "prop-types";

import WizardContext from "../context";
import { Components } from "@ais3p/ui-framework";

/**
 * Компонент отображения Wizard
 * 
 * @param {Element} header  заголовок, отображаемый над активным шагом
 * @param {Element} footer  Нижний колонтитул, отображаемый под активным шагом
 * @param {Element} wrapper Необязательная оболочка, которая обороачивает исключительно вокруг активного компонента 
 * шага.
 * @param {Array <Element>} children Каждый дочерний компонент будет рассматриваться как отдельный шаг.
 * @param {Function} onStepChange Callback ф-я при изменнии шага. Вывзвается с параметром - индекс шага 
 * @param {Function} oDone Callback ф-я при отработке всех этапов в Wizard
 * @param {Number} startIndex С какого шага Wizard должен начать работу
 * @param {Boolena} showControlButtons признак, что нужно отображать кнопки управления шагами
 * @param {String} backBtnLabel текст управляющей кнопки Назад
 * @param {String} backBtnIcon иконка (из UI Framework) управляющей кнопки Назад
 * @param {String} backBtnColor  цвет (из UI Framework) управляющей кнопки Назад
 * @param {String} nextBtnLabel текст управляющей кнопки Далее
 * @param {String} nextBtnIcon иконка (из UI Framework) управляющей кнопки Далее
 * @param {String} nextBtnColor  цвет (из UI Framework) управляющей кнопки Далее
 * @param {String} cancelBtnLabel текст управляющей кнопки Отменить
 * @param {String} cancelBtnIcon иконка (из UI Framework) управляющей кнопки Отменить
 * @param {String} cancelBtnColor  цвет (из UI Framework) управляющей кнопки Отменить
 * @param {String} doneBtnLabel текст управляющей кнопки Готово
 * @param {String} doneBtnIcon иконка (из UI Framework) управляющей кнопки Готово
 * @param {String} doneBtnColor  цвет (из UI Framework) управляющей кнопки Готово
 * @returns {Element}
 */
const Wizard = ({
  /**
   * Заголовок, отображаемый над активным шагом
   * 
   * @type {Element} 
   */
  header,

  /**
   * Нижний колонтитул, отображаемый под активным шагом
   * 
   * @type {Element} 
   */
  footer,

  /**
   * Каждый дочерний компонент будет рассматриваться как отдельный шаг.
   * 
   * @type {Array <Element>}
   */
  children,
  
  /**
   * Callback ф-я при изменнии шага. Вызывается с параметром - индекс шага 
   * 
   * @type {Function}
   */
  onStepChange,

  /**
   * Callback ф-я при отмене Wizard
   * 
   * @type {Function}
   */
  onCancel,

  /**
   * Callback ф-я при отработке всех этапов в Wizard
   * 
   * @type {Function}
   */
  onDone,

  /**
   * Необязательная оболочка, которая обороачивает исключительно вокруг активного компонента шага.
   *  Она не обёрнута вокруг заголовка (header) и нижнего (footer) колонтитула
   */
  wrapper: Wrapper,

  /**
   * С какого шага Wizard должен начать работу. 
   * @type Number
   * @default 0
   */
  startIndex = 0,

  /**
   * Отображать кнопки управления шагами
   * 
   * @type Boolean
   * @default true
   */
  showControlButtons = true,

  /**
   * Текст кнопки Назад
   * 
   * @type String
   * @default "Назад"
   */
  backBtnLabel = "Назад",

  /**
   * Иконка кнопки Назад
   * 
   * @type String
   * @default "chevron-left-M"
   */
  backBtnIcon = "chevron-left-M",

  /**
   * Цвет кнопки Назад
   * 
   * @type String
   * @default "action"
   */
  backBtnColor = "action",

  /**
   * Текст кнопки Далее
   * 
   * @type String
   * @default "Далее"
   */
  nextBtnLabel = "Далее",

  /**
   * Иконка кнопки Далее
   * 
   * @type String
   * @default "chevron-right-M"
   */
  nextBtnIcon = "chevron-right-M",

  /**
   * Цвет кнопки Далее
   * 
   * @type String
   * @default "action"
   */
  nextBtnColor = "action",

  /**
   * Текст кнопки Отменить
   * 
   * @type String
   * @default "Отменить"
   */
  cancelBtnLabel = "Отменить",

  /**
   * Иконка кнопки Отменить
   * 
   * @type String
   * @default "cancel-M"
   */
  cancelBtnIcon = "cancel-M",

  /**
   * Цвет кнопки Отменить
   * 
   * @type String
   * @default "negative"
   */
  cancelBtnColor = "negative",

  /**
   * Текст кнопки Готово
   * 
   * @type String
   * @default "Готово"
   */
  doneBtnLabel = "Готово",

  /**
   * Иконка кнопки Готово
   * 
   * @type String
   * @default "ok-M"
   */
  doneBtnIcon = "ok-M",

  /**
   * Цвет кнопки Готово
   * 
   * @type String
   * @default "positive"
   */
  doneBtnColor = "positive",

  isLoading: initLoading
}) => {
  const [activeStep, setActiveStep] = useState(startIndex);
  const [isLoading, setIsLoading] = useState(initLoading);
  const [isValid, setIsValid] = useState(false);
  const [stepData, setStepData] = useState();
  const hasNextStep = useRef(true);
  const hasPreviousStep = useRef(false);
  const nextStepHandler = useRef(() => {});
  const doneHandler = useRef(() => {});
  const stepCount = Children.toArray(children).length;

  hasNextStep.current = activeStep < stepCount - 1;
  hasPreviousStep.current = activeStep > 0;

  const goToNextStep = useCallback(() => {
    if (hasNextStep.current) {
      const newActiveStepIndex = activeStep + 1;
      setIsValid(false);

      setActiveStep(newActiveStepIndex);
      onStepChange && onStepChange(newActiveStepIndex);
    }
  }, [activeStep, onStepChange]);

  const goToPreviousStep = useCallback(() => {
    if (hasPreviousStep.current) {
      nextStepHandler.current = null;
      doneHandler.current = null;
      const newActiveStepIndex = activeStep - 1;

      setIsValid(false);
      setActiveStep(newActiveStepIndex);
      onStepChange && onStepChange(newActiveStepIndex);
    }
  }, [activeStep, onStepChange]);

  const goToStep = useCallback((stepIndex) => {
    if (stepIndex >= 0 && stepIndex < stepCount) {
      nextStepHandler.current = null;
      doneHandler.current = null;
      setIsValid(false);
      setActiveStep(stepIndex);
      onStepChange && onStepChange(stepIndex);
    } else {
      console.warn(
        [
          `Передан не верный параметр [${stepIndex}] в метод 'goToStep'. `,
          "Убедитесь, что заданный stepIndex не выходит за пределы доступных шагов."
        ].join(""),
      );
    }
  },
  [stepCount, onStepChange],
  );

  // Прикрепляем сallback ф-ю к переходу на следующий шаг
  const handleStep = useRef((handler) => {
    nextStepHandler.current = handler;
    doneHandler.current = handler;
  });

  const doNextStep = useCallback(async() => {
    if (hasNextStep.current && nextStepHandler.current) {
      try {
        setIsLoading(true);
        await nextStepHandler.current();
        setIsLoading(false);
        nextStepHandler.current = null;
        goToNextStep();
      } catch (error) {
        setIsLoading(false);
        throw error;
      }
    } else {
      goToNextStep();
    }
  }, [goToNextStep]);

  const doDone = useCallback(async() => {
    if (doneHandler.current) {
      try {
        setIsLoading(true);
        await doneHandler.current();
        setIsLoading(false);
        doneHandler.current = null;
      } catch (error) {
        setIsLoading(false);
        throw error;
      }
    }
    if (onDone) {
      onDone(stepData);
    } else {
      console.warn("В <Wizard> не передана callback ф-ия onDone");
    }
  }, [onDone, stepData]);

  const wizardValue = useMemo(
    () => {
      return {
        nextStep:     doNextStep,
        previousStep: goToPreviousStep,
        handleStep:   handleStep.current,
        isLoading,
        activeStep,
        stepCount,
        isFirstStep:  !hasPreviousStep.current,
        isLastStep:   !hasNextStep.current,
        goToStep,
        setIsValid,
        isValid,
        setStepData,
        stepData,
        done:         doDone
      };
    },
    [
      doNextStep,
      goToPreviousStep,
      isLoading,
      activeStep,
      stepCount,
      goToStep,
      setIsValid,
      isValid,
      setStepData,
      stepData,
      doDone
    ],
  );

  /**
   * Кнопки для управления Wizard
   */
  const controlButtons = useMemo(() => {
    if (!showControlButtons) {
      return null;
    }
    const leftButtons = [];
    if (onCancel) {
      leftButtons.push(
        <Components.Button 
          key="cancel"
          icon={cancelBtnIcon}
          text={cancelBtnLabel}
          color={cancelBtnColor}
          onPress={onCancel}
        />
      );
    }
    const rightButtons = [];
    if (!!hasPreviousStep.current) {
      rightButtons.push(
        <Components.Button 
          key="previous"
          icon={backBtnIcon}
          text={backBtnLabel}
          color={backBtnColor}
          onPress={goToPreviousStep}
        />
      );
    }

    if (!!hasNextStep.current) {
      rightButtons.push(
        <div className="revert-button">
          <Components.Button 
            key="next"
            isDisabled={isLoading || !isValid}
            icon={nextBtnIcon}
            text={nextBtnLabel}
            color={nextBtnColor}
            onPress={doNextStep}
            isLoading={isLoading}          
          />
        </div>
      );
    }

    if (!hasNextStep.current) {
      rightButtons.push(
        <Components.Button 
          key="done"
          isDisabled={isLoading || !isValid}
          icon={doneBtnIcon}
          text={doneBtnLabel}
          color={doneBtnColor}
          onPress={doDone}
          isLoading={isLoading || initLoading}          
        />
      );
    }
    
    return (
      <div className="control-buttons">
        <div className="control-buttons-left">
          {leftButtons}
        </div>
        <div className="control-buttons-right">
          {rightButtons}
        </div>
      </div> 
    );
  }, [showControlButtons, onCancel, onDone, goToPreviousStep, doNextStep, isValid, stepData, initLoading]);

  const activeStepContent = useMemo(() => {
    const reactChildren = Children.toArray(children);

    // Не переданы панели для шагов
    if (reactChildren.length === 0) {
      console.warn("В <Wizard> необходимо обязательно передать набор панелей (children) для шагов");
    }
    // Не верно передан параметр startIndex.
    if (activeStep > reactChildren.length) {
      console.warn("В <Wizard> не верно передан параметр startIndex");
    }
    // Не верный заголовок - header
    if (header && !isValidElement(header)) {
      console.error("В <Wizard> не верно передан header. Не является React.Element");
    }
    // Не верный нижний колонтитул - footer
    if (footer && !isValidElement(footer)) {
      console.error("В <Wizard> не верно передан footer. Не является React.Element");
    }

    return reactChildren[activeStep];
  }, [activeStep, children, header, footer]);

  const enhancedActiveStepContent = useMemo(
    () => {
      return (Wrapper
        ? cloneElement(Wrapper, { children: activeStepContent })
        : activeStepContent);
    },
    [Wrapper, activeStepContent],
  );

  return (
    <WizardContext.Provider value={wizardValue}>
      {header}
      {enhancedActiveStepContent}
      {footer}
      {controlButtons}
    </WizardContext.Provider>
  );
};

Wizard.propTypes = {
  header:             PropTypes.element,
  footer:             PropTypes.element,
  wrapper:            PropTypes.element,
  children:           PropTypes.arrayOf(PropTypes.element).isRequired,
  onStepChange:       PropTypes.func,
  onCancel:           PropTypes.func,
  onDone:             PropTypes.func,
  startIndex:         PropTypes.number,
  showControlButtons: PropTypes.bool,

  backBtnLabel:   PropTypes.string,
  backBtnIcon:    PropTypes.string,
  backBtnColor:   PropTypes.string,
  nextBtnLabel:   PropTypes.string,
  nextBtnIcon:    PropTypes.string,
  nextBtnColor:   PropTypes.string,
  cancelBtnLabel: PropTypes.string,
  cancelBtnIcon:  PropTypes.string,
  cancelBtnColor: PropTypes.string,
  doneBtnLabel:   PropTypes.string,
  doneBtnIcon:    PropTypes.string,
  doneBtnColor:   PropTypes.string,

  isLoading: PropTypes.bool
};


export default Wizard;
