import { observer } from "mobx-react";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Components, ContextMenu, Notification } from "@ais3p/ui-framework";

import Target from "~/core/components/Target";
import useStores from "~/core/utils/useStores";
import AisIcon from "~/core/components/AisIcon";

import DataStore from "../stores/DataStore";

import PlusMenu from "./PlusMenu";
import RubricTree from "./RubricTree";
import SplitPane from "react-split-pane";

import "../css/text.scss";
import { DOMAIN_TEXT } from "../../../core/constants/Domains";
import { TREE_CFG } from "../constants/config";

import {
  SIDEPANEL_RELATIONS,
  SIDEPANEL_KINDS_ATTRS,
  SIDEPANEL_JOURNAL
} from "~/core/constants/SidePanels";
import { generateAisObjectUrl } from "~/core/utils/aisHelpers";
import { truncateString, copyTextToClipboard, getDomainByClass } from "~/core/utils";

import FlatList from "./FlatList";
import { SIDEPANEL_QA_REQ, SIDEPANEL_VALIDATION } from "../../../core/constants/SidePanels";
import InfoToolWindow from "~/core/components/InfoToolWindow/InfoToolWindow.jsx";
import infoToolContent from "~/core/components/InfoToolWindow/infoToolContent.js";
import VersionButton from "./VersionButton";
import { CLS_TEXT_CHUNK_INLINE_CODE, CLS_TEXT_CHUNK_INLINE_FORMULA, CLS_TEXT_CHUNK_INLINE_PICTURE, CLS_TEXT_CHUNK_VARCHAR, CLS_TEXT_PICTURE } from "../../../core/constants/Classes";
import { getClassTitle } from "~/core/constants/Classes";
import DropdownButton from "~/core/components/Dropdown/DropdownButton";
import iconRender from "~/core/utils/IconRender";

const MIN_TOOL_WIDTH = 300;

const Material = observer((props) => {
  const {
    uid,
    editable,
    focusUid: selectedUid,
    version,
    trackedItem,
    isGlobal,
    layoutItem,
    isTracking,
    tabId,
    layoutStore,
    isSubVisible,
    useGlobalTracking
  } = props;
  const { rootStore, uiStore, wsStore, objectStore } = useStores();

  useEffect(() => {
    return () => {
      wsStore.removeTrackingItem({
        itemId: layoutItem.id
      });
    };
  }, [layoutItem.id]);


  const [contextMenu, setContextMenu] = useState([]);

  const dataStore = useMemo(() => {
    const dataStore = new DataStore(rootStore, layoutStore);
    return dataStore;
  }, []);

  const treeWidth = dataStore.getItemConfig(TREE_CFG).width || MIN_TOOL_WIDTH;
  const [splitSize, setSplitSize] = useState(treeWidth);

  const onSplitPosChange = useCallback((width) => {
    setSplitSize(width);
    const cfg = dataStore.getItemConfig(TREE_CFG);
    dataStore.setItemConfig(TREE_CFG, {
      ...cfg,
      width
    });
  }, []);


  // useEffect(() => {
  //   const unloadCallback = (event) => {
  //     event.preventDefault();
  //     dataStore.beforeUnload();
  //     return "";
  //   };
  
  //   window.addEventListener("beforeunload", unloadCallback);
  //   return () => {
  //     return window.removeEventListener("beforeunload", unloadCallback);
  //   };
  // }, [dataStore]);

  const [isDiffVisible, setIsDiffVisible] = useState(false);
  const [diffVersion, setDiffVersion] = useState(null);
  const [infoIsVisible, setInfoIsVisible] = useState(false);
  
  const onToggleInfoModal = useCallback(() => {
    setInfoIsVisible(!infoIsVisible);
  }, [infoIsVisible]);
  
  const isDiffVersionSelected = useMemo(() => {
    return diffVersion !== null;
  }, [diffVersion]);

  const onSelectDiffVersion = useCallback((versionNumber) => {
    setIsDiffVisible(false);
    setDiffVersion(versionNumber);
  }, []);

  const availableVersionRender = useMemo(() => {
    const versions = [];
    if (isDiffVisible && dataStore.availableDiffVersions) {
      if (!dataStore.availableDiffVersions.length) {
        return (
          <div className="no-versions">
            Объект не имеет версий для сравнения
          </div>
        );
      }
      dataStore.availableDiffVersions.forEach((child) => {
        versions.push(
          <VersionButton
            isSelected={child.number === diffVersion}
            name={child.name}
            version={child.number}
            key={`v-${child.number}`}
            onClick={onSelectDiffVersion}
          />
        );
      });
    }
    return versions;
  }, [
    isDiffVisible,
    isDiffVersionSelected,
    diffVersion,
    dataStore.availableDiffVersions
  ]);

  const isDiffLoading = useMemo(() => {
    return dataStore.isPending || dataStore.isDiffPending;
  }, [dataStore.isPending, dataStore.isDiffPending]);

  const toggleDiffPanel = useCallback(() => {
    if (isDiffVisible) {
      setDiffVersion(null);
    }
    setIsDiffVisible(!isDiffVisible);
  }, [isDiffVisible]);

  useLayoutEffect(() => {
    dataStore.initDiff(diffVersion);
  }, [diffVersion]);

  useEffect(() => {
    useGlobalTracking && !isGlobal && layoutItem.toggleGlobalTracking();
  }, [useGlobalTracking, isGlobal]);

  useLayoutEffect(() => {
    if (editable) {
      dataStore.init(editable, uid, version);
      if (version === 0) {
        wsStore.addTrackingItem({
          itemId: layoutItem.id,
          domain: DOMAIN_TEXT,
          uid:    editable,
          version
        });
      }
    }
  }, [editable, version]);

  useLayoutEffect(() => {
    if (selectedUid && dataStore.isReady && !dataStore.isPending) {
      let selObj = dataStore.getItemById(selectedUid);
      if (selObj && selObj.className.includes(CLS_TEXT_CHUNK_VARCHAR, 
        CLS_TEXT_CHUNK_INLINE_CODE, CLS_TEXT_CHUNK_INLINE_FORMULA, CLS_TEXT_CHUNK_INLINE_PICTURE)) {
        selObj = selObj.parent;
      }

      if (selObj) { 
        dataStore.setFocusUid(selObj.uid);
        dataStore.scrollToItemById(selObj.uid);
      }
    }
  }, [selectedUid, dataStore.isReady, dataStore.isPending]);

  const b = dataStore.getItemConfig(TREE_CFG).isVisible;
  const [isTreeVisible, setIsTreeVisible] = useState(b === undefined ? true : b);

  const element = useRef(null);

  const {
    flatItemsArray,
    root,
    focusUid,
    canAddItem,
    focusItem,
    isVersionPending,
    isVersion,
    isPending,
    canSaveVersion,
    styleVersion,
    plusVisible,
    isPlusVisible
  } = dataStore;

  useEffect(() => {
    if (focusUid && focusItem) {
      layoutItem.changeContext(uid, focusUid, {
        uid:         focusUid,
        editable:    uid,
        version,
        trackedItem: {
          uid:   focusUid,
          version,
          name:  focusItem.title,
          class: focusItem.className,
          tool:  DOMAIN_TEXT
        }
      });
    } else {
      layoutItem.changeContext();
    }
  }, [focusUid, focusItem, uid, version, layoutItem]);

  const isExpanded = root && root.isExpanded;

  const setScrollToIndex = useCallback(
    (index) => {
      const id = flatItemsArray[index] && flatItemsArray[index].id;
      if (id) {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }
    },
    [flatItemsArray, uid, version]
  );

  const itemsLength = useMemo(() => {
    return (flatItemsArray && flatItemsArray.length) || null;
  }, [flatItemsArray]);

  const hasItems = useMemo(() => {
    return itemsLength && itemsLength > 1;
  }, [itemsLength]);

  const onToggleTree = useCallback(() => {
    const isVisible = !isTreeVisible;
    const cfg = dataStore.getItemConfig(TREE_CFG);
    dataStore.setItemConfig(TREE_CFG, {
      ...cfg,
      isVisible
    });
    setIsTreeVisible(isVisible);
  }, [isTreeVisible]);

  
  const toggleGlobalTracking = useCallback(() => {
    layoutItem.toggleGlobalTracking();
  }, [layoutItem]);

  const toggleSubPanel = useCallback(
    (tag) => {
      layoutStore.toggleSubPanel(tabId, tag);
    },
    [layoutStore, tabId]
  );

  const isSaveVersion = useMemo(() => {
    return version === undefined || version === null || version === 0;
  }, [version]);

  const onSaveVersion = useCallback(async() => {
    if (dataStore.availableDiffVersions && dataStore.availableDiffVersions.length) {
      const lastVersion = dataStore.availableDiffVersions[dataStore.availableDiffVersions.length - 1].number;
      const data = await dataStore.getDiffData(lastVersion);
      if (data && data.changeList && data.changeList.length === 0) {
        Notification.warning("Версия не может быть создана. Редакция идентична последней зафиксированной версии.", 
          { autoClose: 2500 });
      } else {
        const res = dataStore.saveVersion(uid);
        if (res) {
          uiStore.setSuccessText("Версия успешно создана.");
        }
      }
    } else {
      const res = dataStore.saveVersion(uid);
      if (res) {
        uiStore.setSuccessText("Версия успешно создана.");
      }
    }
  }, [dataStore, uid]);

  const onToggleRelations = useCallback(() => {
    toggleSubPanel(SIDEPANEL_RELATIONS);
  }, []);
  const onToggleKinds = useCallback(() => {
    toggleSubPanel(SIDEPANEL_KINDS_ATTRS);
  }, []);
  const onToggleLog = useCallback(() => {
    toggleSubPanel(SIDEPANEL_JOURNAL);
  }, []);
  const onToggleValidation = useCallback(() => {
    toggleSubPanel(SIDEPANEL_VALIDATION);
  }, []);
  const onToggleQAReq = useCallback(() => {
    toggleSubPanel(SIDEPANEL_QA_REQ);
  }, []);
  

  const onTogglePlus = useCallback(() => {
    dataStore.setPlusVisible(!isPlusVisible);
  }, [isPlusVisible]);
  
  const openFile = useCallback((item) => {
    layoutStore.open({
      name:      item.title || item.name,
      id:        item.uid || item.id,
      icon:      iconRender(item, true),
      component: "viewer",
      props:     {
        type: item.fileType,
        name: item.title || item.name,
        icon: iconRender(item, true),
        file: item.fileId || item.id
      }
    });
  }, []);

  const exportBtnFunc = useCallback(async(format) => {
    const data = await dataStore.exportToForm(editable, { format, version });
    const domain = data && getDomainByClass(data.class);
    const node = await objectStore.processLibraryItem(data, domain, {});
    if (node) {
      const btnForNotify = (
        <Components.Button
          key="Open"
          text="Открыть"
          // eslint-disable-next-line react/jsx-no-bind
          onPress={() => {
            return openFile(node);
          }}
        />
      );
      Notification.success(`Материал "${node.title || node.name}" был успешно экспортирован`, { btnComponent: btnForNotify, autoClose: 5000 });
    }
  }, []);

  const onDoWithSelectedExport = useCallback(async(menuItem) => {
    switch (menuItem.action) {
      case "exportPdf": 
        exportBtnFunc("pdf");
        break;
      case "exportDoc": 
        exportBtnFunc("doc");
        break;
      case "exportDocx": 
        exportBtnFunc("docx");
        break;
      case "exportOdt": 
        exportBtnFunc("odt");
        break;
      default:
        return;
    }
  }, []);

  const ToolBar = useMemo(() => {
    const buttons = [
      <Components.Button
        key={"tree"} 
        icon="app-tree-M" 
        isSelected={isTreeVisible}
        tooltip={"Отобразить дерево рубрик"}
        onPress={onToggleTree}
      />
    ];
    if (editable && !isVersion && !isDiffVersionSelected) {
      buttons.push(<Components.Spacer key="plus-spacer" />);
      buttons.push(
        <Components.Button
          key={"plus"} 
          icon="plus-M" 
          text={"Добавить"}
          isLoading={isVersionPending}
          isDisabled={!canAddItem}
          tooltip={"Добавить элемент"}
          isSelected={plusVisible}
          onPress={onTogglePlus}
        />
      );
    }
    if (isVersion) {
      buttons.push(<Components.Spacer key="plus-spacer" />);
      buttons.push(
        <DropdownButton
          key={"export-M"}  
          icon="export-M"
          text={"Экспорт"}
          tooltip={"Экспорт"}
          items={[{
            title:  "PDF",
            icon:   "file-pdf-M",
            action: "exportPdf"
          },
          {
            title:  "DOC",
            icon:   "file-doc-M",
            action: "exportDoc"
          },
          {
            title:  "DOCX",
            icon:   "file-docx-M",
            action: "exportDocx"
          },
          {
            title:  "ODT",
            icon:   "file-odt-M",
            action: "exportOdt"
          }]}
          onClickItem={onDoWithSelectedExport}
        />
      );
    }
    const rightButtons = [
      (
        <Components.Button
          key={"diff"} 
          icon="fork-M" 
          tooltip={"Сравнить с версией"}
          isSelected={isDiffVersionSelected}
          isLoading={isDiffLoading}
          onPress={toggleDiffPanel}
        />
      ),
      (<Components.Spacer key="right-spacer" />),
      (
        <Components.Button
          key={"app-relations-M"} 
          icon="app-relations-M" 
          tooltip={"Связи"}
          onPress={onToggleRelations}
          isSelected={isSubVisible[SIDEPANEL_RELATIONS]}
        />
      ),
      (
        <Components.Button
          key={"app-attributes-M"} 
          icon="app-attributes-M" 
          tooltip={"Виды и атрибуты"}
          onPress={onToggleKinds}
          isSelected={isSubVisible[SIDEPANEL_KINDS_ATTRS]}
        />
      ),
      (
        <Components.Button
          key={"log-M"} 
          icon="log-M" 
          tooltip={"Журнал изменений"}
          onPress={onToggleLog}
          isSelected={isSubVisible[SIDEPANEL_JOURNAL]}
        />
      ),
      (
        <Components.Button
          key={"ok-M"} 
          icon="ok-M" 
          tooltip={"Согласование"}
          onPress={onToggleValidation}
          isSelected={isSubVisible[SIDEPANEL_VALIDATION]}
        />
      ),
      (
        <Components.Button
          key={"check-M"} 
          icon="check-M" 
          tooltip={"Оценка качества требований"}
          onPress={onToggleQAReq}
          isSelected={isSubVisible[SIDEPANEL_QA_REQ]}
        />
      ),
      (<Components.Spacer key="right-spacer-2" />),
      (
        <Components.Button
          key={"info-M"} 
          icon="info-M" 
          tooltip={"Справка"}
          onPress={onToggleInfoModal}
          isSelected={infoIsVisible}
        />
      )
    ];

    if (isSaveVersion) {
      rightButtons.unshift(<Components.Spacer key="right-spacer-3" />);
      rightButtons.unshift(
        <Components.Button
          key={"save"} 
          icon="version-M" 
          text={"Создать версию"}
          isLoading={isVersionPending}
          isDisabled={!canSaveVersion}
          tooltip={"Зафиксировать версию"}
          onPress={onSaveVersion}
        />
      );
    }

    return (
      <Components.ToolBar right={rightButtons}>
        {buttons}
      </Components.ToolBar>
    );
  }, [
    isTreeVisible,
    onToggleTree,
    isGlobal,
    toggleGlobalTracking,
    onToggleRelations,
    onToggleKinds,
    onToggleLog,
    onToggleValidation,
    isSubVisible,
    isSaveVersion,
    isVersion,
    isVersionPending,
    isDiffVisible,
    isDiffVersionSelected,
    isDiffLoading,
    onSaveVersion,
    onToggleInfoModal,
    isPlusVisible,
    plusVisible,
    canAddItem,
    onTogglePlus,
    editable
  ]);

  const toggleExpanded = useCallback(
    () => {
      root && root.setExpanded(!isExpanded);
    },
    [isExpanded]
  );

  const hideDiffSelector = useCallback(() => {
    setIsDiffVisible(false);
  }, []);

  const itemsRendered = useMemo(() => {
    if (!dataStore || dataStore.isPending) {
      return null;
    }
    return (
      <FlatList 
        setContextMenu={setContextMenu}
        dataStore={dataStore}
        flatItemsArray={flatItemsArray} 
      />
    );
  }, [flatItemsArray, dataStore.isPending]);
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".plus-container")) {
        if (isExpanded) {
          toggleExpanded(); // Закрываем плюс меню, если оно открыто
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isExpanded, toggleExpanded]);

  const contextMenuCollect = useCallback(
    () => {
      toggleExpanded();
      const menuItems = [];
      if (root) {
        const items = [];
        root.plusMenuItems.forEach((item) => {
          if (item.availableKindsArray.length) {
            const level = Math.ceil(item.level / 2);
            const children = [];
            item.availableKindsArray.forEach((availableKind) => {
              children.push({
                icon:   availableKind.icon || item.icon,
                title:  availableKind.title || getClassTitle(item.class),
                action: "addItem",
                data:   { ...item, kind: availableKind }
              });
            });
            if (children.length === 1) {
              items.push({ 
                ...children[0], 
                title: `${getClassTitle(item.class)}${item.level >= 0 ? ` уровень ${level}` : ""}` 
              });
            } else if (children.length > 1) {
              items.push({
                icon:  item.icon,
                title: `${getClassTitle(item.class)} ${item.title ? item.title : "1"}${item.level >= 0 ? ` уровень ${level}` : ""}`,
                children
              });
            }
          }
        });
        menuItems.unshift({
          icon:     "newelement-before-M",
          title:    "Добавить",
          children: items
        });
      }
      setContextMenu(menuItems);
    }, [setContextMenu, root]);

  const itemsList = useMemo(() => {
    return (
      <div ref={element} className="items-list">
        {!hasItems && root && (
          <ContextMenu.Trigger
            menuId={root.editable}
            context={root}
            collect={contextMenuCollect}
            className={"plus-container"}
            onClick={true}
          >
            <AisIcon
              onContextMenu={toggleExpanded}
              icon={"plus-M"}
              className={`expand-menu ${isExpanded ? "expanded" : ""}`}
            />
            <span className="plus-start-text">Нажмите, чтобы начать работу</span>
          </ContextMenu.Trigger>
        )}
        {hasItems && itemsRendered}
      </div>
    );
  }, [hasItems, root, toggleExpanded, isExpanded, itemsRendered]);

  /**
   * Скрыть окно подтверждения
   */
  const onCancelConfirm = useCallback(() => {
    uiStore.hideConfirm();
  }, []);

  const doAction = useCallback((actionFn) => {
    uiStore.setConfirmPending(true);
    try {
      actionFn && actionFn();
      uiStore.hideConfirm();
    } finally {
      uiStore.setConfirmPending(false);
    }
  }, [uiStore]);

  /**
   * Отобразить окно для подтверждения действия удаления объекта
   * 
   * @param {String} action тип действия
   * @param {TextObject} item текстовый объект, над которым происходит действие
   */
  const showDeleteConfirm = useCallback((action, item) => {
    let icon = "delete-M";
    let title = "Удаление текстового объекта";
    let content = `Вы действительно хотите удалить тектовый объект "${item.title}"?`;
    let actionFn = () => {};

    switch (action) {
      case "delete":{
        icon = "delete-M";
        title = "Удаление текстового объекта";
        content = (
          <div className="confirm-delete-content">
            Вы действительно хотите удалить 
            <div
              className="text-content-confirm" 
            >
              <AisIcon
                className={"expander big text-content-confirm-icon"} 
                item={item}
              />{truncateString(item.title, 32)}
            </div>?
          </div>
        );
        actionFn =  item.selfDestruct.bind(item);
        break;
      }

      case "deleteRow":{
        icon = "table-row-minus-M";
        title = "Удаление строки из таблицы";
        content = "Вы действительно хотите удалить строку из таблицы?";
        actionFn =  item.deleteRow.bind(item);
        break;
      }

      case "deleteColumn":{
        icon = "table-col-minus-M";
        title = "Удаление колонки из таблицы";
        content = "Вы действительно хотите удалить колонку из таблицы?";
        actionFn =  item.deleteColumn.bind(item);
        break;
      }

      default: {
        return;
      }
    };

    uiStore.setConfirm({
      icon,
      title,
      content, 
      buttons: [
        <Components.Button
          key="delete"
          text="Удалить"
          icon={icon}
          onPress={() => { // eslint-disable-line
            doAction(actionFn);
          }}
          color="negative"
        />,
        <Components.Button
          key="cancel"
          text="Отмена"
          icon="cancel-M"
          onPress={onCancelConfirm}
          color="dark"
        />
      ],
      onKeyPressEsc:   onCancelConfirm,
      onKeyPressEnter: () => { // eslint-disable-line
        doAction(actionFn);
      }
    });
  }, [doAction, onCancelConfirm]);

  /**
   * Создать url на тектовый объект
   * 
   * @param {*} item 
   * @returns 
   */
  const createUrlToItem = async(item) => {
    const url = await generateAisObjectUrl(item, objectStore);
    if (url) {
      copyTextToClipboard(url.href, 
        () => {
          Notification.success(
            `URL ссылка на объект "${truncateString(item.title, 32)}" скопирована в буфер обмена`, { autoClose: 1000 });
        }, 
        (err) => {
          Notification.warning(
            `URL ссылка на объект "${truncateString(item.title, 32)}" не скопирована в буфер обмена: ${err}`, 
            { autoClose: 1000 });
        });
    }
    return;
  };

  
  const onMenuClick = useCallback(async(action, item, data) => {
    switch (action) {
      case "delete":
        // item.selfDestruct();
        showDeleteConfirm(action, item);
        break;
      case "createUrl":
        createUrlToItem(item);
        break;
      case "addItem":{
        const newItem = await item.createItem(data, data.kind);
        if (newItem.className === CLS_TEXT_PICTURE) {
          newItem.setEditingPicture(true);
        }
        break;
      }
      case "editPicture":
        item.setEditingPicture(true);
        break;
      case "editCaption":
        item.setEditing(true);
        break;
      case "insertColumnLeft":
        item.insertColumnLeft();
        break;
      case "insertColumnRight":
        item.insertColumnRight();
        break;
      case "insertRowTop":
        item.insertRowTop();
        break;
      case "insertRowBottom":
        item.insertRowBottom();
        break;
      case "deleteRow":
        showDeleteConfirm(action, item);
        // item.deleteRow();
        break;
      case "deleteColumn":
        showDeleteConfirm(action, item);
        // item.deleteColumn();
        break;
      case "mergeCells":
        item.mergeCells();
        break;
      case "splitCellVert":
        item.splitCell("vertically");
        break;
      case "lock":
        item.lock();
        break;
      case "unlock":
        item.unlock();
        break;
      case "setVariantOrdered":
        item.setEnumerationVariant("ordered-list");
        break;
      case "setVariantUnordered":
        item.setEnumerationVariant("unordered-list");
        break;
      case "splitCellHor":
        item.splitCell("horizontally");
        break;
      case "setInsertingInlinePicture": {
        item.setInsertInlinePicture(true);
      }
        break;
      case "insertInlineFormula": {
        await item.insertFormula(data); 
      }
        break;
      default:
        break;
    }
  }, []);

  if (!editable) {
    return (
      <div className={`material ${isVersion ? "version" : ""}`}>
        {ToolBar}
        <div className={"editor-container"}>
          {(isGlobal || isTracking) && <Target trackedItem={trackedItem} />}
          <div className="items-list-holder">
            <div className="no-content">
              <AisIcon icon="cancel-M" /> Не текст
            </div>
          </div>
        </div>
        {infoIsVisible && (
          <InfoToolWindow 
            content={infoToolContent.text}
            infoIsVisible={infoIsVisible}
            toggleInfoModal={onToggleInfoModal}
          />
        )}  
      </div>
    );
  }

  if (isPending) {
    return (
      <div className={`material ${isVersion ? "version" : "latest"}`}>
        {ToolBar}
        <div className={"editor-container"}>
          {(isGlobal || isTracking) && <Target trackedItem={trackedItem} />}
          <div className="items-list-holder">
            <div className="no-content">
              <Components.Preloader size={3} />
            </div>
          </div>
        </div>
        {infoIsVisible && (
          <InfoToolWindow 
            content={infoToolContent.text}
            infoIsVisible={infoIsVisible}
            toggleInfoModal={onToggleInfoModal}
          />
        )}  
      </div>
    );
  }

  return (
    <div
      className={`material text ${isVersion ? `style-version-${styleVersion}` : "latest"} ${
        isDiffVersionSelected ? "with-diff" : ""
      }`}
    >
      {ToolBar}
      <div onClick={hideDiffSelector} className={"editor-container"}>
        {isDiffVisible && (
          <div className="version-List">{availableVersionRender}</div>
        )}
        {isPlusVisible && (
          <div className="plus-menu-container">
            <PlusMenu data={focusItem} />
          </div>
        )}
        {isDiffVersionSelected && (
          <div className="object-holder">
            <div className="target-object">
              <div className="object-name shrink-0">Сравнение</div>
              <AisIcon
                data-tooltip={
                  dataStore.libraryRepresentation &&
                  dataStore.libraryRepresentation.title
                }
                item={dataStore.libraryRepresentation}
              />
              <div className="object-name">
                {dataStore.libraryRepresentation &&
                  dataStore.libraryRepresentation.title}
              </div>
              <AisIcon
                data-tooltip={
                  dataStore.version === 0
                    ? "Редакция"
                    : `Версия ${dataStore.version}`
                }
                className="added-icon"
                icon={"version-M"}
              />
              <div className="object-name shrink-0">
                {dataStore.version === 0
                  ? "Редакция"
                  : `Версия ${dataStore.version}`}
              </div>
              <AisIcon backgroundColor="#4d4d4d" icon="arrow-right-M" />
              <AisIcon
                data-tooltip={`Версия ${
                  dataStore.diffStore && dataStore.diffStore.version
                }`}
                className="removed-icon"
                icon={"version-M"}
              />
              <div className="object-name shrink-0">
                {`Версия ${dataStore.diffStore && dataStore.diffStore.version}`}
              </div>
            </div>
          </div>
        )}
        {!isDiffVersionSelected && dataStore.libraryRepresentation && (
          <div className="object-holder">
            <div
              className="target-object"
            > 
              <AisIcon 
                data-tooltip={dataStore.libraryRepresentation && dataStore.libraryRepresentation.title} 
                item={dataStore.libraryRepresentation}
              />
              <div className="object-name">
                {dataStore.libraryRepresentation && dataStore.libraryRepresentation.title}
              </div>
              &ndash;
              <AisIcon 
                data-tooltip={dataStore.version === 0 ? "Редакция" : `Версия ${dataStore.version}`} 
                icon={"version-M"}
              />
              <div className="object-name">
                {dataStore.version === 0 ? "Редакция" : `${dataStore.version}`}
              </div>
            </div>
          </div>
        )}
        {(isGlobal || isTracking) && (
          <Target trackedItem={trackedItem} />
        )}
        <div className="items-list-holder">
          {isTreeVisible && (
            <SplitPane
              split="vertical"
              minSize={isTreeVisible ? MIN_TOOL_WIDTH : 0}
              style={{ flex: 1 }}
              onChange={onSplitPosChange}
              size={splitSize}
            >
              <div className="rubric-tree-holder">
                {isTreeVisible && (
                  <RubricTree
                    uid={uid}
                    version={version}
                    setContextMenu={setContextMenu}
                    setScrollToIndex={setScrollToIndex}
                    store={dataStore}
                  />
                )}
              </div>
              {itemsList}
            </SplitPane>
          )}
          {!isTreeVisible && itemsList}
        </div>
      </div>
      {infoIsVisible && (
        <InfoToolWindow 
          content={infoToolContent.text}
          infoIsVisible={infoIsVisible}
          toggleInfoModal={onToggleInfoModal}
        />
      )}
      <ContextMenu.Menu
        id={dataStore.editable} 
        items={contextMenu} 
        onMenuClick={onMenuClick}
      />
    </div>
  );
});

export default Material;
