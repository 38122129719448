import { observable, computed, action } from "mobx";
// import UserModel from "~/modules/users/models/User";
import { processAisObject } from "~/core/utils/aisHelpers";
import DataStore from "../../newText/stores/DataStore";

/**
 * Модель записи журнала действия пользователя
 *
 * @class JournalModel
 */
class JournalModel {
  /**
   * временная метка действия;
   * @type DateTime
   */
  @observable
  datetime = undefined;

  @observable
  subject = undefined;


  @observable
  params = undefined;

  @observable
  paramsMap = new Map();

  @observable
  representation = undefined;

  /**
   * Хранилище журнала
   *
   * @type JournalStore
   */
  @observable
  journalStore = undefined;


  /**
   * Cоздание модели
   *
   * @param {Object} params параметры модели
   * @param {String} params.uid uid записм
   * @param {String} params.subject  uid - идентификатор пользователя;
   * @param {String} params.object uid - идентификатор объекта, над которым происходит действие;
   * @param {String} params.class класс объекта в системе АИС ППП;
   * @param {Number} params.version версия объекта в системе АИС ППП
   * @param {String} params.action тип действия (create, read, update, delete);
   * @param {DateTime} params.datetime временная метка действия;
   * @param {Object} params.payload полезная нагрузка, опциональный параметр, содержит новое состояние объекта;
   * @param {UserStore} userStore хранилище пользователей в АИС
   *
   * @retrun {JournalModel}
   */
  static create({
    datetime,
    subject,
    params,
    representation
  }, journalStore) {
    return new JournalModel({
      datetime,
      subject,
      params,
      representation
    }, journalStore);
  }

  constructor(params, journalStore) {
    this.datetime = params.datetime;
    this.subject = params.subject;
    this.params = params.params || {};
    this.representation = params.representation || "";
    
    this.journalStore = journalStore;
    this.processRepresantation();
  }

  /**
   * Вид модели
   * @return {String}
   */
  get kindModel() {
    return "journalModel";
  }

  async processRepresantation() {
    const regexp = /{\w+}/gi;
    const matches = this.representation.match(regexp) || [];
    const paramsMap = new Map();
    await Promise.all(matches.map(async(name) => {
      const paramRepr = this.params[name];
      if (paramRepr) {
        try {
          if (Array.isArray(paramRepr)) {
            // создаю textStore для каждого параметра, тк тексковое представление у ондного и того же объекта
            // может различаться
            const textStore = new DataStore(this.journalStore && this.journalStore.rootStore);
            const arr = await Promise.all(paramRepr.map(async(p) => {
              let res;
              try {
                res = await processAisObject(p, this.journalStore.rootStore.objectStore, false, textStore);
              } catch (ex) {
                console.error("processAisObject error:", ex);
              }
              
              return res;
            }));
            paramsMap.set(name, arr);
          } else {
            // создаю textStore для каждого параметра, тк тексковое представление у ондного и того же объекта
            // может различаться
            const textStore = new DataStore(this.journalStore && this.journalStore.rootStore);
            const obj = await processAisObject(paramRepr, 
              this.journalStore.rootStore.objectStore, false, textStore);
            paramsMap.set(name, obj);
          }
        } catch (ex) {
          console.error(`Во время преобразования объекта с именем "${name}" произошла ошибка: ${ex.message}`);
        }
      } else {
        console.error(`Не определен параметр с именем "${name}"`);
      }
    }));
    this.setParamsMap(paramsMap);
  }

  @action
  setParamsMap(map) {
    this.paramsMap = map;
  }

  /**
   * @computed
   * Строковое представление записи журнала
   *
   * @return {String}
   */
  @computed
  get title() {
    return this.representation.replace(/{\w+}/gi, (paramName) => {
      const obj = this.paramsMap.get(paramName);
      if (!obj) {
        // console.error(`Не определен объект с параметром "${name}"`);
        return paramName;
      }
      let title = obj.title;
      if (Array.isArray(obj)) {
        title = obj.map((item) => {
          return item && item.title;
        }).join(", ");
      }
      return title || paramName;
    });
  }

  /**
   * @computed
   * Объект АИС пользователя
   *
   * @return {UserModel}
   */
  @computed
  get user() {
    if (!this.subject) {
      return;
    }
    return this.journalStore.rootStore.userStore.get(this.subject);
  }
}

export default JournalModel;
