import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";

import { Components } from "@ais3p/ui-framework";

import { DOMAIN_ISSUE } from "~/core/constants/Domains";
import Target from "~/core/components/Target";
import { generateFiltersData } from "../filters";
import IssueTabs from "./IssueTabs";
import IssuesToolbar from "../toolbar";
import IssuePanel from "./IssuePanel";
import { FILTERS_CFG } from "../../constants/config";
import { STATUS_OPEN } from "../../constants/statuses";
import IssueStore from "../../stores/IssueStore";


/**
 * Панель для работы с задачами
 * 
 * @param {Object} props набор параметров
 * @param {String} params.projectUid uid проекта
 * @param {String} params.issueUid uid задачи
 * @param {IssueStore} params.store хранилище для работы с задачами
 * @param {Boolean} params.canBeEditable признак, можно ли редактирвоать Задачу
 * @param {Object} params.trackedItem данные об отслеживаемом объекте в Layout
 * @param {String} params.tabId id вкладки в Layout
 * @param {LayoutStore} params.layoutStore хранилище для работы с Layout
 * @param {LayoutItem} params.layoutItem сущность в Layout
 * @param {Object} params.isSubVisible флаг отображения боковой доп панели
 */
const IssuesContent = observer(
  ({
    projectUid,
    issueUid,
    store,
    canBeEditable,
    trackedItem,
    tabId,
    layoutStore,
    layoutItem,
    isSubVisible
  }) => {
    const [issue, setIssue] = useState();

    useEffect(() => {
      // if (projectUid) {
      //   // если в контексте был передан uid проекта, то загружаем список задач для проекта
      //   loadIssues(projectUid);
      // }

      store.setTrackedItem(trackedItem);
      if (issueUid) {
        loadIssue(issueUid);
      } else if (projectUid) {
        loadIssues(projectUid);
      }
    }, [projectUid, issueUid, store.issuesViewMode, trackedItem]);

    const loadIssues = (projectUid) => {
      // setIsPending(true);
      try {
        const cfgFilters = store.getItemConfig(FILTERS_CFG).values;
        if (projectUid) {
          if (cfgFilters) {
            const data = generateFiltersData(cfgFilters, projectUid);
            store.loadIssuesByFilter(data);
          } else {
            store.loadIssuesByFilter({
              project: [projectUid],
              status:  [STATUS_OPEN]
            });
          }
        }
      } finally {
        // setIsPending(false);
      }
    };

    const reloadIssues = useCallback(() => {
      if (projectUid) {
        loadIssues(projectUid);
      }
    }, [projectUid, trackedItem]);

    const loadIssue = async(uid) => {
      // setIsPending(true);
      try {
        const i = await store.loadIssue(uid);
        layoutItem.changeContext(i.project.uid, i.uid, {
          uid:         i.uid,
          trackedItem: {
            uid:   i.uid,
            name:  i.title,
            class: i.class,
            tool:  DOMAIN_ISSUE
          }
        });
        store.setActiveIssue(i);
        setIssue(i);
      } finally {
        // setIsPending(false);
      }
    };

    return (
      <div className="issues">
        {(projectUid  || issueUid) && (
          <IssuesToolbar
            store={store}
            canBeEditable={canBeEditable}
            tabId={tabId}
            layoutStore={layoutStore}
            isSubVisible={isSubVisible}
            issueUid={issueUid}
            projectUid={projectUid}
          />
        )}
        {projectUid && <Target id={projectUid} trackedItem={trackedItem} />}
        {store.isProcessing && (
          <Components.Preloader size={3} className="issues-preloader" />
        )}
        <div className="issues-wrapper">
          <div className="issues-body">
            <div
              className={classNames(
                "issues-content",
                "issues-panel",
                "issue-list"
              )}
            >
              {projectUid && (
                <IssueTabs
                  store={store}
                  canBeEditable={canBeEditable}
                  projectUid={projectUid}
                  tabId={tabId}
                  layoutStore={layoutStore}
                  layoutItem={layoutItem}
                  isSubVisible={isSubVisible}
                  reloadIssues={reloadIssues}
                />
              )}
              {issue && (
                <IssuePanel
                  issue={issue}
                  store={store}
                  canBeEditable={canBeEditable}
                  tabId={tabId}
                  layoutStore={layoutStore}
                  layoutItem={layoutItem}
                  isSubVisible={isSubVisible}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

IssuesContent.propTypes = {
  projectUid:    PropTypes.string,
  issueUid:      PropTypes.string,
  store:         PropTypes.instanceOf(IssueStore),
  canBeEditable: PropTypes.bool,
  trackedItem:   PropTypes.object,
  tabId:         PropTypes.string,
  layoutStore:   PropTypes.object,
  layoutItem:    PropTypes.object,
  isSubVisible:  PropTypes.object
};

export default IssuesContent;
