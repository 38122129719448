import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Components } from "@ais3p/ui-framework";

/**
 * Компонент для отображения записи для выбора поля карточки Задачи, которая пойдет в отчет КП
 * @param {String} id id поля 
 * @param {String} title название поля
 * @param {Boolean} pivotCell признак выбранности поля для сводной таблицы
 * @param {Boolean} issueCell признак выбранности поля для карточки задачи
 * @param {Function} onTogglePivotCell ф-я для переключения состояния выбранности поля для сводной таблицы
 * @param {Function} onToggleIssueCell ф-я для переключения состояния выбранности поля для карточки задачи
 * @returns {Element}
 */
const TableFieldRow = ({ id, title, pivotCell, issueCell, onTogglePivotCell, onToggleIssueCell }) => {
  const onClickPivotCheckBox = useCallback(() => {
    onTogglePivotCell && onTogglePivotCell(id);
  }, [id, onTogglePivotCell]);

  const onClickIssueCheckBox = useCallback(() => {
    onToggleIssueCell && onToggleIssueCell(id);
  }, [id, onTogglePivotCell]);

  return (
    <tr>
      <td>
        <div className="title">
          {title}
        </div>
      </td>
      <td className="check-box">
        <div className="check-box">
          <Components.Icon 
            name={pivotCell ? "checkbox-yes-S" : "checkbox-no-S"} 
            onClick={onClickPivotCheckBox} 
          />
        </div>
      </td>
      <td>
        <div className="check-box">
          <Components.Icon 
            name={issueCell ? "checkbox-yes-S" : "checkbox-no-S"} 
            onClick={onClickIssueCheckBox} 
          />
        </div>
      </td>
    </tr>
  );
};

TableFieldRow.propTypes = {
  id:                PropTypes.string || PropTypes.number,
  title:             PropTypes.string,
  pivotCell:         PropTypes.bool, 
  issueCell:         PropTypes.bool,
  onTogglePivotCell: PropTypes.func, 
  onToggleIssueCell: PropTypes.func
};


export default TableFieldRow;
