import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import { Components } from "@ais3p/ui-framework";
import useStores from "../utils/useStores";

/**
 * Логотип АИС ППП
 * 
 * @param {Function} onShowAbout callback ф-я для отображения иноформации "О системе"
 */
const AppLogo = observer(({ onShowAbout }) => {
  const { wsStore, uiStore } = useStores();

  const onClickShowAbout = useCallback(() => {
    onShowAbout && onShowAbout(true);
  }, [onShowAbout]);

  return (
    <div className="ais-logo-wrapper">
      <Components.Icon
        onClick={onClickShowAbout}
        className="ais-logo-icon"
        name="plane-M"
      />
      {!wsStore.isConnected && (
        <div
          className={classNames("ais-logo-ws-status", "left", {
            connected: wsStore.isConnected
          })}
        />
      )}
      {wsStore.isConnected && (
        <div
          className={classNames("ais-logo-ws-status", "right", {
            connected: wsStore.isConnected
          })}
        />
      )}
      {uiStore.isPendingTasks && (
        <div
          className={"ais-logo-request-pending"}
          {...{
            "data-tooltip":    uiStore.pendingTasksList,
            "data-tooltip-at": "bottom"
          }}
        />
      )}
    </div>
  );
});

AppLogo.propTypes = {
  onShowAbout: PropTypes.func.isRequired
};
export default AppLogo;
