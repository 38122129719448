import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { useWizard } from "~/modules/wizard2";
import { BASE_PROPERTIES, propertyNameText } from "../../constants/propertyNames";

import FieldItem from "./TableFieldRow";
import IssueStore from "../../stores/IssueStore";
import { COORD_LETTER_FIELDS_PIVOT_CFG, COORD_LETTER_FIELDS_ISSUES_CFG } from "../../constants/config";

/**
 * Последний шаг в Wizard для выбора полей задач, которые будут использоваться при формировании сводной 
 * таблицы и для каждой задачи в КП
 * 
 * @params {IssueStore} store хранилище для работы с задачами
 * 
 * @returns {Element}
 */
const Step3 = ({ store }) => {
  const {
    setIsValid,
    handleStep,
    stepData: template, // здесь передали модель выбранног шаблона
    setStepData
  } = useWizard();

  const [fieldsInPivotTable, setFieldsInPivotTable] = useState(
    store.getItemConfig(COORD_LETTER_FIELDS_PIVOT_CFG) || {}); // список полей для сводной таблицы
  const [fieldsInEachIssue, setFieldsInEachIssue] = useState(
    store.getItemConfig(COORD_LETTER_FIELDS_ISSUES_CFG) || {}); // список полей для каждой задачи

  const fieldList = useMemo(() => {
    const fields = [];
    BASE_PROPERTIES.forEach((id) => {
      fields.push({ id, title: propertyNameText(id), isCustom: false });
    });

    store.allCustomFieldsInSelectedIssues.forEach((customField) => {
      fields.push({ id: customField.id, title: customField.name, isCustom: true });
    });
    return fields;
  }, [BASE_PROPERTIES, store.allCustomFieldsInSelectedIssues.length]);

  handleStep(() => {
    store.setItemConfig(COORD_LETTER_FIELDS_PIVOT_CFG, fieldsInPivotTable || {});
    store.setItemConfig(COORD_LETTER_FIELDS_ISSUES_CFG, fieldsInEachIssue || {});
  });

  const onTogglePivotCell = useCallback((id) => {
    setFieldsInPivotTable((state) => {
      return {
        ...state,
        [id]: !state[id]
      };
    });
  }, [fieldsInPivotTable]);

  const onToggleIssueCell = useCallback((id) => {
    setFieldsInEachIssue((state) => {
      return {
        ...state,
        [id]: !state[id]
      };
    });
  }, [fieldsInEachIssue, fieldsInEachIssue]);

  useEffect(() => {
    let resPivot = false;
    let resIssue = false;
    // Для каждой колонки дб выбрано зотя бы одно поле  в Задаче
    Object.keys(fieldsInPivotTable).forEach((key) => {
      resPivot = resPivot || fieldsInPivotTable[key];
    });

    Object.keys(fieldsInEachIssue).forEach((key) => {
      resIssue = resIssue || fieldsInEachIssue[key];
    });

    const isValid = resIssue && resPivot;
    setIsValid(isValid);
    if (isValid) {
      const memoCols = [];
      const exportCols = [];
      fieldList.forEach((f) => {
        if (fieldsInPivotTable[f.id]) {
          memoCols.push({
            identificator: f.id,
            label:         f.title,
            isCustom:      f.isCustom
          });
        }
        if (fieldsInEachIssue[f.id]) {
          exportCols.push({
            identificator: f.id,
            label:         f.title,
            isCustom:      f.isCustom
          });
        }
      });
      template.setMemoCols(memoCols);
      template.setExportCols(exportCols);
      setStepData(template);  
    }
  }, [fieldsInPivotTable, fieldsInEachIssue, setStepData, setIsValid, setIsValid.template, fieldList]);

  const allPivotUnselectable = useMemo(() => {
    return Object.keys(fieldsInPivotTable).every((key) => {
      return fieldsInPivotTable[key] === false;
    });
  }, [fieldsInPivotTable]);

  const onSelectAllPivotCells = useCallback(() => {
    const res = {};
    fieldList.forEach(({ id }) => {
      res[id] = true;
    });
    setFieldsInPivotTable(res);
  }, [fieldList]);

  const onUnselectAllPivotCells = useCallback(() => {
    const res = {};
    fieldList.forEach(({ id }) => {
      res[id] = false;
    });
    setFieldsInPivotTable(res);
  }, [fieldList]);

  const allIssuesUnselectable = useMemo(() => {
    return Object.keys(fieldsInEachIssue).every((key) => {
      return fieldsInEachIssue[key] === false;
    });
  }, [fieldsInEachIssue]);

  const onSelectAllIssuesCells = useCallback(() => {
    const res = {};
    fieldList.forEach(({ id }) => {
      res[id] = true;
    });
    setFieldsInEachIssue(res);
  }, [fieldList]);

  const onUnselectAllIssuesCells = useCallback(() => {
    const res = {};
    fieldList.forEach(({ id }) => {
      res[id] = false;
    });
    setFieldsInEachIssue(res);
  }, [fieldList]);

  return (
    <div className="coord-letter-step">
      <div className="coord-letter-step-header">
        <h2>Задайте необходимые поля из карточки Задача</h2>
      </div>
      <div className="coord-letter-step-body">
        <div className="table-content">
          <table>
            <thead>
              <tr>
                <th>
                  <label>Наименование поля в Задаче</label>
                </th>
                <th className="check-box">
                  <label>В сводной таблице</label>
                  {allPivotUnselectable ?
                    <div 
                      className="select-item"
                      onClick={onSelectAllPivotCells}
                    >Выбрать <br />все</div> :
                    <div 
                      className="select-item"
                      onClick={onUnselectAllPivotCells}
                    >Отменить <br />все</div>
                  } 
                </th>
                <th className="check-box">
                  <label>В каждой карточке Задачи</label>
                  {allIssuesUnselectable ?
                    <div 
                      className="select-item"
                      onClick={onSelectAllIssuesCells}
                    >Выбрать <br /> все</div> :
                    <div 
                      className="select-item"
                      onClick={onUnselectAllIssuesCells}
                    >Отменить <br /> все</div>
                  } 
                </th>
              </tr>
            </thead>
            <tbody>
              {fieldList.map(({ id, title }) => {
                return (
                  <FieldItem 
                    key={id}
                    id={id}
                    title={title}
                    pivotCell={fieldsInPivotTable[id]}
                    issueCell={fieldsInEachIssue[id]}
                    onTogglePivotCell={onTogglePivotCell}
                    onToggleIssueCell={onToggleIssueCell}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

Step3.propTypes = {
  store: PropTypes.instanceOf(IssueStore)
};

export default Step3;
