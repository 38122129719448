import React from "react";
import PropTypes from "prop-types";
import MenuItem from "./MenuItem";

/**
 * Выпадающий список из кнопки DropdownButton
 * 
 * @param {Number} depthLevel уровень вложености меню
 * @param {Boolean} isDropdown признак отображения меню
 * @param {Array<Object>} items конфигурация пунктов и подпунктов выпадющего списка. 
 * Конфигурация Item должна содержать в себе: 
 *   title {String} текст пункта 
 *   icon {String} название иконки из ui-framework, если у пункта необходима иконка  
 *   items {Array<Object>} конфигурация вложенных пунктов
 * @param {Function} onClickItem  callback ф-я на нажатие одного из пунктов в меню. 
 * В парметре приходит item - конфигурация пункта
 * @param {Number} menuIndex уровень ункта меню. Нужен, чтобы отображать правильно по высоте
 * вложенный подпункт. TODO - нужно будет это сделать лучше как-то на стилях
 * 
 * @returns {Element}
 */
const MenuItems = ({ items, depthLevel, isDropdown, onClickItem, menuIndex = 0 }) => {
  const dropdownClass = depthLevel >= 1 ? "dropdown-button-submenu" : "";

  return (
    <ul 
      className={`dropdown-button-menu ${dropdownClass} ${isDropdown ? "show" : ""}`}
      style={{ top: menuIndex === 0 ? "auto" : `${((menuIndex - 1) * 2) - 0.125}rem` }}
    >
      <div className="dropdown-button-menu-content">
        {items.map((item, i) => {
          return (
            <MenuItem
              key={i}
              index={i + 1}
              item={item}
              depthLevel={depthLevel + 1}
              onClickItem={onClickItem}
            />
          );
        })}
      </div>
    </ul>
  );
};

MenuItems.propTypes = {
  items:       PropTypes.array,
  depthLevel:  PropTypes.number,
  isDropdown:  PropTypes.bool,
  menuIndex:   PropTypes.number,
  onClickItem: PropTypes.func 
};

export default MenuItems;
