import React, { useState, useCallback, useEffect } from "react";
import { observer } from "mobx-react";
import { Components, Field, Notification } from "@ais3p/ui-framework";
import useStores from "~/core/utils/useStores";

/**
 * Форма для замены пароля у текущего пользователя
 *
 * @class ChangePassword
 */
const ChangePassword = observer(() => {
  const [isValid, setIsValid] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [paswordsNotEqual, setPaswordsNotEqual] = useState(false);
  const [newPasswordEqualWithOld, setNewPasswordEqualWithOld] = useState(false);
  const { accountStore, userStore } = useStores();

  useEffect(() => {
    const res = password === passwordConfirm &&
            validateOldPassword(oldPassword).isValid &&
            userStore.validatePassword(password).isValid &&
            userStore.validatePassword(passwordConfirm).isValid;
    setIsValid(res);
    setPaswordsNotEqual(password !== passwordConfirm);
    setNewPasswordEqualWithOld(password === oldPassword && !!password);
  }, [password, passwordConfirm]);

  const doChangePassword = useCallback(async() => {
    const b = await userStore.updatePassword(accountStore.uid, oldPassword, password);
    if (b) {
      setPassword("");
      setOldPassword("");
      setPasswordConfirm("");
      Notification.success(
        "Пароль успешно изменен!", 
        { autoClose: 2000 });
    }
  }, [accountStore.uid, password, oldPassword]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
  }, []);

  const onChangeOldPassword = useCallback((value) => {
    setOldPassword(value);
  }, []);

  const onChangePassword = useCallback((value) => {
    setPassword(value);
  }, []);

  const onChangePasswordConfirm = useCallback((value) => {
    setPasswordConfirm(value);
  }, []);

  const validateOldPassword = useCallback((password) => {
    if (password === "") {
      return {
        isValid: false,
        hint:    "Пожалуйста задайте старый пароль для входа в систему"
      };
    }
    return { isValid: true };
  }, []);

  return (
    <div className="change-password">
      {userStore.isPending && 
        <Components.Preloader size={3} className="preloader-center" />
      }
      <div className="change-password-body">
        <div className="change-password-body-left">
          <div className="change-password-description">
            <h1>Требования к паролю:</h1>
            <span>{userStore.passwordRegExpDescription}</span>
          </div>
        </div>
        <div className="change-password-body-right">
          <form onSubmit={handleSubmit} action="#">
            <Field.String
              icon=""
              label="Старый пароль"
              name="old-user-password"
              typeOfInput="password"
              value={oldPassword}
              isDisabled={userStore.isPending}
              onChange={onChangeOldPassword}
              {...validateOldPassword(oldPassword)}
            />
            <Field.String
              icon=""
              label="Новый пароль"
              name="new-user-password"
              typeOfInput="password"
              value={password}
              isDisabled={userStore.isPending}
              onChange={onChangePassword}
              {...userStore.validatePassword(password)}
            />
            <Field.String
              icon=""
              label="Повторите  новый пароль"
              name="new-user-password-confirm"
              typeOfInput="password"
              value={passwordConfirm}
              isDisabled={userStore.isPending}
              onChange={onChangePasswordConfirm}
              {...userStore.validatePassword(passwordConfirm)}
            />
          </form>
          {
            paswordsNotEqual &&
            <div className="passwords-not-equal">Пароли не совпадают</div>
          }
          {
            newPasswordEqualWithOld &&
            <div className="passwords-not-equal">Нельзя устанавливать старый пароль</div>
          }
        </div>
      </div>
      <div className="change-password-footer">
        <Components.Button
          onPress={doChangePassword}
          isDisabled={!isValid || userStore.isPending || paswordsNotEqual || newPasswordEqualWithOld}
          isLoading={userStore.isPending}
          color="positive"
          icon="ok-M"
          text={"Задать"}
        />
      </div>
    </div>
  );
});

export default ChangePassword;
