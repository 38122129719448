import packageJson from "~/../package.json";

const compatible = [
  ["ui.spa", packageJson.version],
  ["ui.framework", "0.7.0"],
  // 
  ["transport.gateway", "0.5.0"],
  //
  ["storage.files", "0.4.2"],
  // 
  ["common.admin", "0.7.1"],
  ["common.journal", "0.3.0"],
  ["common.styles", "0.1.0"],
  ["common.users", "0.2.2"],
  // 
  ["app.approvals", "0.1.0"],
  ["app.binder", "0.3.0"],
  ["app.composer", "0.2.1"],
  ["app.kindsattrs", "0.6.4"],
  ["app.library", "0.7.3"],
  ["app.sourcecode", "0.2.2"],
  ["app.tables", "0.2.4"],
  ["app.tasks", "0.3.0"],
  ["app.text", "0.21.0"],
  ["app.tracer", "0.7.1"],
  ["app.workflow", "2.9.9"],
  //
  ["impexp.reqs", "0.2.2"],
  ["impexp.pdf", "0.2.5"],
  ["impexp.off", "0.5.4"],
  ["impexp.mathml", ""]
];

const servicesVersions = [];
for (const dependency of compatible) {
  servicesVersions.push({ name: dependency[0], version: dependency[1] });
}
export default servicesVersions;
export { servicesVersions };
