import React, { useState, useCallback, useEffect, useMemo } from "react";
import { observer } from "mobx-react";
import { Components, Field } from "@ais3p/ui-framework";
import UserModel from "~/modules/users/models/User";
import UserAvatar from "./UserAvatar";
import useStores from "~/core/utils/useStores";

/**
 * Форма отображения/редактирования пользователя
 *
 * @class UserForm
 */
const UserForm = observer(() => {
  const { accountStore, userStore } = useStores();
  const { user } = accountStore;

  const useRedmine = true;
  
  const [isValid, setIsValid] = useState(false);
  const [firstname, setFirstname] = useState(user.firstname);
  const [lastname, setLastname] = useState(user.lastname);
  const [patronymic, setPatronymic] = useState(user.patronymic);
  const [email, setEmail] = useState(user.email);
  const [image, setUserImage] = useState(user.image);

  useEffect(async() => {
    await userStore.loadRedmineUsers();
  }, []);

  useEffect(() => {
    const res = UserModel.validateFirstName(firstname).isValid &&
            UserModel.validateLastName(lastname).isValid &&
            UserModel.validatePatronymic(patronymic).isValid &&
            UserModel.validateEmail(email, userStore, user.uid).isValid;
    setIsValid(res);
  }, [firstname, lastname, patronymic, email, user.uid]);

  const isModified = useMemo(() => {
    if (!user) {
      return false;
    }
    const res = user.firstname !== firstname ||
      user.lastname !== lastname ||
      user.patronymic !== patronymic ||
      user.email !== email ||
      user.image !== image;
    return res;
  }, [user && user.firstname, user && user.lastname, user && user.patronymic, user && user.email, user && user.image, 
    firstname, lastname, patronymic, email, image]);

  const onSave = useCallback(async() => {
    const u = await userStore.saveUser(user.uid, { firstname, lastname, patronymic, email, image }, useRedmine);
    if (u) {
      accountStore.setUser(u);
    }
  }, [firstname, lastname, patronymic, email, image]);

  const onCancel = useCallback(() => {
    setFirstname(user.firstname);
    setLastname(user.lastname);
    setPatronymic(user.patronymic);
    setEmail(user.email);
    setUserImage(user.image);
  }, [
    user && user.firstname, 
    user && user.lastname, 
    user && user.patronymic, 
    user && user.email, 
    user && user.image
  ]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
  }, []);

  const onChangeFirstName = useCallback((value) => {
    setFirstname(value);
  }, []);

  const onChangeLastName = useCallback((value) => {
    setLastname(value);
  }, []);

  const onChangePatronymic = useCallback((value) => {
    setPatronymic(value);
  }, []);

  const onChangeEmail = useCallback((value) => {
    setEmail(value);
  }, []);

  const onChangeLogin = useCallback(() => {
  }, []);

  const onUploadAvatar  = useCallback((value) => {
    setUserImage(value);
  }, [user, image]);

  return (
    <div className="user-form">
      {userStore.isPending && 
        <Components.Preloader size={3} className="preloader-center" />
      }
      <div className="user-form-body">
        <div className="user-form-body-left">
          <UserAvatar
            user={user}
            image={image}
            userStore={userStore}
            onUploadAvatar={onUploadAvatar}
          />
          <div className="user-name">
            {user && user.name}
          </div>
          <div className="user-login">
            {user && user.login}
          </div>
          
          <div className="user-groups-wrapper">
            <span className="user-groups-wrapper-title">Группы: </span>
            <span className="user-groups-wrapper-values">
              {
                user.groupArray.map((gr) => {
                  return gr.name;
                }).join(", ")
              }
            </span>
          </div>
        </div>
        <div className="user-form-body-right">
          <div className="user-form-header">
            Личные данные
          </div>    
          <form onSubmit={handleSubmit} action="#">
            <Field.String
              icon=""
              label="Логин"
              name="user-login"
              type="text"
              isDisabled={true}
              value={user && user.login}
              onChange={onChangeLogin}
            />
            <Field.String
              icon=""
              label="Фамилия"
              name="user-lastname"
              value={lastname}
              isDisabled={userStore.isPending}
              onChange={onChangeLastName}
              {...UserModel.validateLastName(lastname)}
            />
            <Field.String
              icon=""
              label="Имя"
              name="user-firstname"
              value={firstname}
              isDisabled={userStore.isPending}
              onChange={onChangeFirstName}
              {...UserModel.validateFirstName(firstname)}
            />
            <Field.String
              icon=""
              label="Отчество"
              name="user-patronymic"
              value={patronymic}
              isDisabled={userStore.isPending}
              onChange={onChangePatronymic}
              {...UserModel.validatePatronymic(patronymic)}
            />
            <Field.String
              icon=""
              label="E-mail"
              name="user-email"
              type="email"
              value={email}
              isDisabled={userStore.isPending}
              onChange={onChangeEmail}
              {...UserModel.validateEmail(email, userStore, user.uid)}
            />
          </form>
        </div>
      </div>
      <div className="user-form-footer">
        <Components.Button
          onPress={onCancel}
          color="negative"
          icon="cancel-M"
          isDisabled={!isModified || userStore.isPending}
          text={"Отменить"}
        />
        <Components.Button
          onPress={onSave}
          isDisabled={!isModified || !isValid || userStore.isPending}
          isLoading={userStore.isPending}
          color="positive"
          icon="ok-M"
          text={"Сохранить"}
        />
      </div>
      
    </div>
  );
});

export default UserForm;
